import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import sha256 from 'crypto-js/sha256';

import { appInsights } from '../AppInsights';
import Config from '../Config';
import { PopUpEventTypes, Views } from '../utilities/constants';
import { Startup } from '../models/Startup';
import { registerPendo } from '../utilities/pendo';
import { app } from 'src/auth/TeamsJsWrapper';

declare const pendo: any;

interface LoggingContext {
  limeadeAccountId: string;
  userStoreId: string;
  companyId: string;
  employerId: string;
  msTeamsContext: string;
}

type EventType =
  | 'AchievementsViewShowing'
  | 'AuthFlowStarted'
  | 'AuthFlowFinished'
  | 'AuthFlowError'
  | 'ButtonClicked'
  | 'ChatOpening'
  | 'DiscoverViewShowing'
  | 'ErrorLogged'
  | 'FilterApplied'
  | 'FilterAppliedThenDetails'
  | 'FilterAppliedThenJoin'
  | 'HomeViewShowing'
  | 'InformationLogged'
  | 'NotificationClicking'
  | 'PopupOpenOrClose'
  | 'ProgressTracked'
  | 'SetInvitation'
  | 'TeamCreated'
  | 'TeamInvitationRequired'
  | 'TeamJoined'
  | 'TeammatesToInviteSelected'
  | 'TeamUpdated'
  | 'UsersUpdated'
  | 'ViewChanged';

export class Events {
  static AchievementsViewShowing: EventType = 'AchievementsViewShowing';
  static AuthFlowStarted: EventType = 'AuthFlowStarted';
  static AuthFlowFinished: EventType = 'AuthFlowFinished';
  static AuthFlowError: EventType = 'AuthFlowError';
  static ButtonClicked: EventType = 'ButtonClicked';
  static ChatOpening: EventType = 'ChatOpening';
  static DiscoverViewShowing: EventType = 'DiscoverViewShowing';
  static ErrorLogged: EventType = 'ErrorLogged';
  static FilterApplied: EventType = 'FilterApplied';
  static FilterAppliedThenDetails: EventType = 'FilterAppliedThenDetails';
  static FilterAppliedThenJoin: EventType = 'FilterAppliedThenJoin';
  static HomeViewShowing: EventType = 'HomeViewShowing';
  static InformationLogged: EventType = 'InformationLogged';
  static NotificationClicking: EventType = 'NotificationClicking';
  static PopupOpenOrClose: EventType = 'PopupOpenOrClose';
  static ProgressTracked: EventType = 'ProgressTracked';
  static SetInvitation: EventType = 'SetInvitation';
  static TeamCreated: EventType = 'TeamCreated';
  static TeamInvitationRequired: EventType = 'TeamInvitationRequired';
  static TeamJoined: EventType = 'TeamJoined';
  static TeammatesToInviteSelected: EventType = 'TeammatesToInviteSelected';
  static TeamUpdated: EventType = 'TeamUpdated';
  static UsersUpdated: EventType = 'UsersUpdated';
  static ViewChanged: EventType = 'ViewChanged';
}

class Logger {
  public static initialized: boolean = false;
  public static authorized: boolean = false;
  private static _logger: ApplicationInsights = appInsights;
  private static _context: LoggingContext = {
    limeadeAccountId: '',
    userStoreId: '',
    companyId: '',
    employerId: '',
    msTeamsContext: '',
  };
  static resolveStartupDataLoadedPromise = (value?: any) => {};
  static startupDataLoadedPromise = new Promise((resolve) => {
    Logger.resolveStartupDataLoadedPromise = resolve;
  });

  public static async initialize() {
    if (Logger.initialized) {
      return;
    }

    const msTeamsContext = await app.getContext();
    Logger._context.msTeamsContext = JSON.stringify(msTeamsContext);

    Logger.initialized = true;
  }

  public static authorize(startupData: Startup) {
    if (Logger.authorized) {
      return;
    }

    if (startupData.EnablePendoComponent) {
      registerPendo();
      Logger.initPendo(startupData);
    }

    Logger.initAppInsights(startupData);
    Logger.authorized = true;
  }

  public static unauthorize() {
    if (!Logger.authorized) {
      return;
    }

    if (typeof pendo !== 'undefined') {
      pendo.identify({});
    }

    Logger.context.limeadeAccountId = '';
    Logger.context.companyId = '';
    Logger.context.userStoreId = '';
    Logger.context.employerId = '';
    Logger.authorized = false;
  }

  public static initPendo(startupData: Startup) {
    const {
      UserStoreId,
      CompanyId,
      EmployerId,
      UserInfo: { LanguageCode },
    } = startupData;

    const visitorId = `one${UserStoreId}`;
    const hashedVisitorId = sha256(visitorId).toString();

    pendo.initialize({
      visitor: {
        id: hashedVisitorId,
        lmeLanguage: LanguageCode,
      },
      account: {
        id: CompanyId,
        env: Config.nodeEnv,
        companyId: CompanyId,
        employerId: EmployerId,
        isLimeadeOne: true,
      },
    });
  }

  public static initAppInsights(startupData: Startup) {
    const { LimeadeAccountId, CompanyId, UserStoreId, EmployerId } = startupData;

    Logger.context.limeadeAccountId = `${LimeadeAccountId}`;
    Logger.context.companyId = `${CompanyId}`;
    Logger.context.userStoreId = `${UserStoreId}`;
    Logger.context.employerId = `${EmployerId}`;
  }

  public static get context() {
    return Logger._context;
  }

  public static getSessionId(): string {
    if (!Logger.initialized) {
      return '';
    }

    return Logger._logger.context.getSessionId();
  }

  public static logError(errorMessage: string): void {
    if (!Logger.initialized) {
      return;
    }

    Logger.trackEvent(Events.ErrorLogged, { message: errorMessage });
  }

  public static logMessage(message: string): void {
    if (!Logger.initialized) {
      return;
    }

    Logger.trackEvent(Events.InformationLogged, { message });
  }

  private static buildCustomProperties(properties = {}) {
    return {
      ...Logger.context,
      ...properties,
    };
  }

  public static trackEvent(eventType: EventType, customProperties?: any): void {
    if (!Logger.initialized) {
      return;
    }

    (async () => {
      await Logger.startupDataLoadedPromise;
      Logger._logger.trackEvent({ name: eventType }, Logger.buildCustomProperties(customProperties));
    })();
  }

  public static trackButtonClick(buttonName?: string, tabOrComponentId?: string): void {
    if (!Logger.initialized) {
      return;
    }

    Logger.trackEvent(Events.ButtonClicked, {
      buttonName,
      tabOrComponentId,
    });
  }

  public static trackException(exception?: any): void {
    if (!Logger.initialized) {
      return;
    }

    const cause = exception?.cause?.isAxiosError
      ? {
          status: exception?.cause?.response?.status,
          statusText: exception?.cause?.response?.statusText,
          url: exception?.cause?.config?.url,
          params: exception?.cause?.config?.params,
          errors: exception?.cause?.response?.data?.errors,
        }
      : exception?.cause;

    (async () => {
      await Logger.startupDataLoadedPromise;
      Logger._logger.trackException({ exception }, Logger.buildCustomProperties({ cause }));
    })();
  }

  public static trackPopupOpenOrClose(popup: PopUpEventTypes, isPopupOpen: boolean): void {
    if (!Logger.initialized) {
      return;
    }

    Logger.trackEvent(Events.PopupOpenOrClose, {
      popup,
      isPopupOpen,
    });
  }

  public static trackSendInvitation(activityId: string, teamId: number) {
    if (!Logger.initialized) {
      return;
    }

    Logger.trackEvent(Events.SetInvitation, {
      activityId,
      teamId,
    });
  }

  public static trackViewChanging(previousView: Views, newView: Views) {
    if (!Logger.initialized) {
      return;
    }

    Logger.trackEvent(Events.ViewChanged, {
      previousView,
      newView,
    });
  }
}

export default Logger;
