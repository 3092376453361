import { observer } from 'mobx-react-lite';

import DeviceRequiredDialog from '../DeviceRequiredDialog';
import PrivacyDialog from '../PrivacyDialog';
import { useStore } from 'src/stores/Root.store';

const ApplicationDialog = observer(() => {
  const { ApplicationStore } = useStore();

  return (
    <>
      {ApplicationStore.isDeviceRequiredDialogOpen && ApplicationStore.pickedActivity && (
        <DeviceRequiredDialog activity={ApplicationStore.pickedActivity} />
      )}
      {ApplicationStore.isPrivacyDialogOpen && ApplicationStore.pickedActivity && (
        <PrivacyDialog activity={ApplicationStore.pickedActivity!} />
      )}
    </>
  );
});

export default ApplicationDialog;
