import { useTranslation } from 'react-i18next';
import { Flex, Loader } from '@fluentui/react-northstar';
import withLoadingAnnouncement from '../LoadingAnnouncementProvider/withLoadingAnnouncement';

import { ILoadableData } from 'src/lib/useData';

const LimeadeLoader = () => {
  const { t } = useTranslation();

  return (
    <Flex hAlign="center" styles={{ height: '100%' }} vAlign="center">
      <Loader label={t('customStrings.loading', 'Loading...')} />
    </Flex>
  );
};

export const renderElement = (data: ILoadableData, element: JSX.Element) => {
  if (data.loading) {
    return <LimeadeLoader />;
  }
  if (data.isReady()) {
    return element;
  }
  return <></>;
};

export default LimeadeLoader;

export const LoadingAnnouncement = withLoadingAnnouncement(LimeadeLoader);
