import { AadUser } from 'src/models/AadUser';
import ExceptionTypes from './exceptionTypes';
import { GraphClient } from 'src/auth/TeamsGraphAuth';

export default class GraphApiWrapper {
  graphClient: GraphClient;

  constructor(graphClient: GraphClient) {
    this.graphClient = graphClient;
  }

  getAadUsers = async (aadUserIds: string[]): Promise<AadUser[]> => {
    try {
      const url = `/users/microsoft.graph.getByIds`;
      const payload = {
        ids: aadUserIds,
        types: ['user'],
      };
      const response = await this.graphClient.api(url).post(payload);
      return response.value;
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_AAD_USERS, { cause: error });
    }
  };
}
