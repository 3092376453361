import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Dialog, Text, Flex } from '@fluentui/react-northstar';
import { faPodcast, faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import deviceRequiredDialogStyles from './DeviceRequiredDialog.styles';
import { TeamsActivity } from 'src/models/Activity';
import { useStore } from 'src/stores/Root.store';
import pendoIdentifierBuilder from 'src/utilities/pendoIdentifierBuilder';
import { currentView, openDeviceConnection } from 'src/utilities/utilities';
import appStyles from 'src/App.styles';
import { SocialTrackingMode } from 'src/utilities/constants';

interface IDeviceRequiredDialogProps {
  activity: TeamsActivity;
}

const DeviceRequiredDialog: React.FC<IDeviceRequiredDialogProps> = observer((props) => {
  const styles = { ...appStyles, ...deviceRequiredDialogStyles };
  const { ApplicationStore, AppAuthStore } = useStore();
  const { t } = useTranslation();

  const { activity } = props;

  const dialogContent = () => {
    return (
      <Flex gap="gap.smaller" vAlign="center">
        <FontAwesomeIcon icon={faPodcast} style={styles.icon} />
        <Text content={t('dialogs.deviceRequired.content')} styles={styles.text} />
      </Flex>
    );
  };

  return (
    <Dialog
      header={
        <Text content={t('dialogs.deviceRequired.headerTitle', { title: activity.title })} styles={styles.header} />
      }
      content={dialogContent()}
      cancelButton={t('buttons.cancel')}
      confirmButton={{
        'data-insightId': pendoIdentifierBuilder({
          component: 'DeviceSetupCard',
          view: currentView(),
          socialTracking: activity.socialTracking ? SocialTrackingMode.TEAM : SocialTrackingMode.SELF,
          trackingType: activity.trackingType,
          frequency: activity.trackingStrategy.frequency,
        }),
        content: t('buttons.setup'),
        icon: <FontAwesomeIcon icon={faExternalLink} />,
        iconPosition: 'after',
      }}
      defaultOpen
      styles={styles.dialog}
      onCancel={ApplicationStore.hideDeviceRequiredDialog}
      onConfirm={() => {
        ApplicationStore.hideDeviceRequiredDialog();
        openDeviceConnection(AppAuthStore.limeadeDomain);
      }}
    />
  );
});

export default DeviceRequiredDialog;
