import { useEffect } from 'react';
import { useStore } from '../stores/Root.store';
import LimeadeLoader from '../components/shared/Loader/Loader';

export default function Auth0Logout() {
  const { AppAuthStore } = useStore();

  useEffect(() => {
    if (!AppAuthStore.isLoading) {
      const logout = () => {
        AppAuthStore.limeadeAuthMethods.logoutWithRedirect();
      };

      logout();
    }
  }, [AppAuthStore.limeadeAuthMethods, AppAuthStore.isLoading]);

  return <LimeadeLoader />;
}
