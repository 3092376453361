import React, { useEffect, useContext } from 'react';
import { LoadingAnnouncementContext, LoadingAnnouncementContextType } from './LoadingAnnouncementProvider';

const withLoadingAnnouncement = (WrappedComponent: any) => {
    const WithLoadingAnnouncement = (props: any) => {
        const { setAnnouncementContent, setAnnouncementToggle } = useContext(LoadingAnnouncementContext) as LoadingAnnouncementContextType;
        const { announcementText } = props;
        useEffect(() => {
            if (announcementText) {
                setAnnouncementToggle(true);
                setAnnouncementContent(announcementText);

                return () => {
                    // Log data on component unmount
                    setAnnouncementToggle(false);
                    setAnnouncementContent(null);
                };
            }
        }, [announcementText, setAnnouncementContent, setAnnouncementToggle]);

        useEffect(() => {
            const clearStatusMessage = setTimeout(() => {
                setAnnouncementToggle(false);
                setAnnouncementContent(null);
            }, 2000);

            return () => {
                clearTimeout(clearStatusMessage);
            }
        }, [setAnnouncementContent, setAnnouncementToggle]);

        return (
            <>
                <WrappedComponent {...props} />
            </>
        );
    };

    return WithLoadingAnnouncement;
};

export default withLoadingAnnouncement;