'use client';
import { ErrorBoundary } from 'react-error-boundary';
import OopsView from '../components/views/OopsView';
import Logger from '../logger/Logger';
interface ErrorLogBoundaryOptions {
  children?: React.ReactNode;
}

const ErrorLogBoundary: React.FC<ErrorLogBoundaryOptions> = ({ children }): JSX.Element => {
  function Fallback({ error }) {
    const id = Logger.getSessionId();
    return <OopsView errorMessage={error.message} sessionId={id} />;
  }

  const logError = (error: Error) => {
    Logger.trackException(error);
  };

  return (
    <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorLogBoundary;
