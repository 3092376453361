import React from 'react';
import ReactZendesk, { ZendeskAPI } from '@limeade/react-zendesk';
import { app as teamsjsApp } from '@microsoft/teams-js';

import Config from 'src/Config';
import { isAuthorization } from 'src/utilities/utilities';
import { app } from 'src/auth/TeamsJsWrapper';

const RTL_LOCALES = ['he', 'ar'];

const Zendesk: React.FC = () => {
  const updateSuggestionsToTeamsArticle = () => {
    const microsoftTeamsArticleSectionId = '360009219972';
    ZendeskAPI('webWidget', 'helpCenter:setSuggestions', { search: 'Microsoft Teams' });
    ZendeskAPI('webWidget', 'updateSettings', {
      helpCenter: {
        filter: {
          section: microsoftTeamsArticleSectionId,
        },
      },
    });
  };

  const localizeWidget = (locale) => {
    ZendeskAPI('webWidget', 'setLocale', locale);

    const isRTL = RTL_LOCALES.some((rtlLocale) => locale.startsWith(rtlLocale));
    if (isRTL) {
      ZendeskAPI('webWidget', 'updateSettings', {
        webWidget: {
          position: { horizontal: 'left' },
        },
      });
    }
  };

  const handleOnLoaded = () => {
    updateSuggestionsToTeamsArticle();

    app.getContext().then((context: teamsjsApp.Context) => {
      localizeWidget(context.app.locale);
    });
  };

  if (isAuthorization()) {
    return null;
  }

  return <ReactZendesk defer zendeskKey={Config.zendeskKey} onLoaded={handleOnLoaded} />;
};

export default Zendesk;
