import React from 'react';
import { Text } from '@fluentui/react-northstar';
import emphasizedStyles from './EmphasizedText.style';
import { useTeamsFx } from 'src/lib/useTeamsFx';

interface EmphasizedTextProps {
  children: string;
  styles?: React.CSSProperties;
  isAriaLabelHidden?: boolean;
  as?: React.ElementType;
}

const EmphasizedText: React.FC<EmphasizedTextProps> = ({ children, styles, as, isAriaLabelHidden = false }) => {
  const { themeInfo } = useTeamsFx();
  emphasizedStyles.backgroundColor = themeInfo.colorScheme.emphasizedBackground;

  return (
    <Text aria-hidden={isAriaLabelHidden} as={as} styles={{ ...emphasizedStyles, ...styles }}>
      {children}
    </Text>
  );
};

export default EmphasizedText;
