import { ThemeInfoEx } from 'src/ThemeInfoEx';

const logoutViewStyles = (themeInfo: ThemeInfoEx) => {
  return {
    button: {
      color: themeInfo.colorScheme.foregroundColor3,
      maxWidth: '250px',
      width: '80%',
      background: themeInfo.colorScheme.buttonBackground1,

      '&:hover': {
        background: themeInfo.colorScheme.buttonHoverBackground1,
        color: themeInfo.colorScheme.foregroundColor3,
      },
      '&:active': {
        background: themeInfo.colorScheme.buttonActiveBackground1,
      },
    },

    questionIcon: {
      color: themeInfo.colorScheme.buttonBackground1,
      background: themeInfo.colorScheme.foregroundColor3,
      padding: '0px',
      '&:hover': {
        color: themeInfo.colorScheme.buttonHoverBackground1,
      },
    },

    logOutButtonArea: {
      width: '100%',
      animationName: 'fadeIn',
      animationDuration: '1s',
    },
    note: {
      whiteSpace: 'pre-line',
      fontSize: '14px',
      paddingLeft: '20px',
      paddingRight: '20px',
      color: '#666666',
      fontWeight: '600',
    },
  };
};

export default logoutViewStyles;
