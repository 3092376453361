import ApiWrapper, { ApiWrapperInterface } from '../utilities/apiWrapper';
import { createContext } from 'react';
import ExceptionTypes from 'src/utilities/exceptionTypes';

export interface ApiContextInterface {
  api: ApiWrapperInterface;
}

export const ApiContext = createContext<ApiContextInterface>({
  api: new ApiWrapper(() => {
    throw new Error(ExceptionTypes.TOKEN_NOT_PROVIDED);
  }),
});
