import { observer } from 'mobx-react-lite';
import { Button, Flex, Text } from '@fluentui/react-northstar';

import { useStore } from 'src/stores/Root.store';
import SplashScreen from 'src/components/shared/SplashScreen/SplashScreen';
import styles from './LogoutView.styles';
import { useTeamsFx } from 'src/lib/useTeamsFx';
import { Redirect } from 'react-router-dom';

interface LogoutViewProps {
  from: string;
}

const LogoutView: React.FC<LogoutViewProps> = observer(({ from }) => {
  const { AppAuthStore, LocalizationStore } = useStore();
  const { themeInfo } = useTeamsFx();
  const style = styles(themeInfo);

  if (AppAuthStore.isAuthenticated) {
    return (
      <SplashScreen>
        <Flex hAlign="center" column={true} vAlign="center" gap="gap.large" styles={style.logOutButtonArea}>
          <Flex
            padding="padding.medium"
            hAlign="center"
            vAlign="center"
            gap="gap.smaller"
            styles={style.logOutButtonArea}
          >
            <Text
              content={LocalizationStore.getString('Fow_LogOutView_Note')}
              align="center"
              size="large"
              styles={style.note}
            />
          </Flex>

          <Flex hAlign="center" vAlign="center" gap="gap.smaller" styles={style.logOutButtonArea}>
            <Button
              id="signOutButton"
              primary
              size="medium"
              styles={style.button}
              content={LocalizationStore.getString('SignOut')}
              onClick={AppAuthStore.limeadeAuthMethods.logoutWithPopup}
            />
          </Flex>
        </Flex>
      </SplashScreen>
    );
  }

  const redirectPath = from ?? '/home';
  return <Redirect to={redirectPath} />;
});

export default LogoutView;
