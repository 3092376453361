import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Dialog, Text, Checkbox, Flex } from '@fluentui/react-northstar';
import { useErrorBoundary } from 'react-error-boundary';

import privacyDialogStyles from './PrivacyDialog.styles';
import { TeamsActivity } from 'src/models/Activity';
import { useStore } from 'src/stores/Root.store';
import pendoIdentifierBuilder from 'src/utilities/pendoIdentifierBuilder';
import { currentView } from 'src/utilities/utilities';
import { SocialTrackingMode } from 'src/utilities/constants';

interface IPrivacyDialogProps {
  activity: TeamsActivity;
}

const PrivacyDialog: React.FC<IPrivacyDialogProps> = observer((props) => {
  const styles = privacyDialogStyles;
  const { ActivityStore, ApplicationStore } = useStore();
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const { activity } = props;

  const dialogContent = () => {
    const label = isPrivate ? t('customStrings.yes') : t('customStrings.no');

    return (
      <Flex column gap="gap.medium">
        <Text content={t('dialogs.privacy.content')} styles={styles.text} />
        <Checkbox label={label} toggle onClick={() => setPrivate(!isPrivate)} styles={styles.checkbox} />
      </Flex>
    );
  };

  const joinActivity = async () => {
    try {
      await ActivityStore.joinActivity(activity.id, activity.title, isPrivate, true);
      ApplicationStore.hidePrivacyDialog();
    } catch (error) {
      showBoundary(error);
    }
  };

  const [isPrivate, setPrivate] = useState<boolean>(false);

  return (
    <Dialog
      header={<Text content={t('dialogs.privacy.headerTitle', { title: activity.title })} styles={styles.header} />}
      content={dialogContent()}
      cancelButton={t('buttons.cancel')}
      confirmButton={{
        'data-insightId': pendoIdentifierBuilder({
          component: 'JoinButton',
          view: currentView(),
          socialTracking: activity.socialTracking ? SocialTrackingMode.TEAM : SocialTrackingMode.SELF,
          trackingType: activity.trackingType,
          frequency: activity.trackingStrategy.frequency,
        }),
        content: t('buttons.join'),
      }}
      defaultOpen
      styles={styles.dialog}
      onCancel={ApplicationStore.hidePrivacyDialog}
      onConfirm={joinActivity}
    />
  );
});

export default PrivacyDialog;
