import { PageableApi, PageableCollection } from './models';

export default class LightPagination {
  pageableApi;
  storedItems: Array<any> = [];
  displayedItems: Array<any> = [];
  curPageItems: Array<any> = [];
  curIndex = 0;
  pageSize = 0;
  totalCount = 0;
  generator?: AsyncGenerator;
  isFetchDone = false;

  constructor(pageableApi: PageableApi<any>, pageSize = 0) {
    this.pageableApi = pageableApi;
    this.pageSize = pageSize;
  }

  getPageSize = () => this.pageSize;

  showMore = async (pageSize?) => {
    if (pageSize) {
      this.pageSize = pageSize;
    }

    if (!this.isFetchDone && this.curIndex === this.storedItems.length) {
      await this.fetchPageableApi();
    }

    if (this.curIndex < this.totalCount) {
      let nextIndex = this.curIndex + this.pageSize;
      if (nextIndex > this.totalCount) {
        nextIndex = this.totalCount;
      }

      this.curPageItems = this.storedItems.slice(this.curIndex, nextIndex);
      this.displayedItems = this.displayedItems.concat(this.curPageItems);
      this.curIndex = nextIndex;
    }
  };

  showLess = (pageSize?) => {
    if (pageSize) {
      this.pageSize = pageSize;
    }

    const nextIndex = this.pageSize < this.totalCount ? this.pageSize : this.totalCount;
    this.curPageItems = this.storedItems.slice(0, nextIndex);
    this.displayedItems = this.curPageItems.slice();
    this.curIndex = nextIndex;
  };

  fetchPageableApiGenerator = async function* fetchPageableApiGenerator(
    pageableApi: PageableApi<any>,
    getPageSize: () => number
  ): AsyncGenerator<PageableCollection<any>> {
    let pagingToken: string | null = null;
    let response: PageableCollection<any>;
    do {
      const pageSize = getPageSize();
      response = await pageableApi(pageSize, pagingToken);
      if (!response) {
        return;
      }

      pagingToken = response.pagingToken;
      yield response;
    } while (pagingToken);
  };

  fetchPageableApi = async () => {
    if (!this.generator) {
      this.generator = this.fetchPageableApiGenerator(this.pageableApi, this.getPageSize);
    }

    let response = (await this.generator.next()).value as PageableCollection<any>;
    if (!response) {
      return;
    }

    this.totalCount = response.totalCount;
    this.storedItems = this.storedItems.concat(response.items);
    this.isFetchDone = this.storedItems.length === this.totalCount;
  };
}
