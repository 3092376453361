const chartBarStyles = {
  root: {
    width: '100%',
  },
  item: {
    marginBottom: '8px',
  },
};

export default chartBarStyles;
