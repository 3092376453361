const ExceptionTypes = {
  TOKEN_NOT_PROVIDED: 'Token is not provided',
  TENANT_ID_NOT_LOADED: 'Tenant Id is not loaded',
  AUTH_PROVIDER_NOT_SUPPORTED: 'Auth provider is not supported',
  USEDATA_INVALID_ARG: 'invalid argument to useData, a function is required',
  PRIVACY_FLAG_NOT_SET: "Privacy flag hasn't been set to the value selected by the user",
  AUTH_CONFIG_FROM_API_INVALID: 'The auth config from API is invalid',
  TENANT_AUTH_RECORD_MISSING: 'Missing tenant authentication record',
  USER_MAPPING_INVALID: 'User mapping is invalid',
  USER_LOCALE_DATA: 'TeamsUserLocale is invalid',
  ACCESS_TOKEN_NULL: 'Access token is null',
  LIMEADE_DOMAIN_NOT_FOUND: 'Limeade domain is empty or undefined. Cannot build the link',
  CANNOT_GET_STARTUPDATA: 'Cannot get the startup data',
  CANNOT_GET_USER_LEVELS: 'Cannot get user levels',
  CANNOT_GET_POINTS_HISTORY: 'Cannot get points history',
  CANNOT_GET_MY_ACTIVITIES: 'Cannot get my activities',
  CANNOT_GET_ACTIVITY_DETAIL: 'Cannot get activity detail',
  CANNOT_FETCH_PAGINATION_DISCOVER_ACTIVITY: 'Cannot fetch pagination discover activities',
  CANNOT_GET_MY_TEAM_LEADERBOARD: 'Cannot get my team leaderboard',
  CANNOT_GET_TEAM_LEADERBOARD: 'Cannot get teams leaderboard',
  CANNOT_GET_TEAM_INFOR: 'Cannot get my teams infor',
  CANNOT_GET_ACTIVITY_BY_ID: 'Cannot get activity by id',
  CANNOT_CANNOT_GET_PROFILE: 'Cannot get profile',
  CANNOT_TRACK_ACTIVITY: 'Cannot track activity',
  CANNOT_GET_ACTIVITY_USER_MAPPING_BY_LIMEADE_ACCOUNTIDS: 'Cannot get user mapping by limeade accountIds',
  CANNOT_GET_RICHMEDIA_INSIGHT_USER_PLAYDETAILS: 'Cannot get richmedia insight user playdetails',
  CANNOT_GET_TEAM_PARTICIPANTS: 'Cannot get team participants',
  CANNOT_GET_CURRENT_TEAM_INFO: 'Cannot get current team info',
  CANNOT_SEND_NOTIFICATION: 'Cannot send notification',
  CANNOT_GET_TENANT_CONFIG_RECORD: 'Cannot get tenant config record',
  CANNOT_CREATE_TEAM: 'Cannot create team',
  CANNOT_GET_LIFE_AREA_SCORES: 'Cannot get life area scores',
  CANNOT_JOIN_ACTIVITY: 'Cannot join activity',
  CANNOT_LEAVE_ACTIVITY: 'Cannot leave activity',
  CANNOT_MOVE_TO_HISTORY: 'Cannot move to history',
  CANNOT_JOIN_TEAM: 'Cannot join team',
  CANNOT_UPDATE_ACTIVITY_PRIVACY: 'Cannot update activity privacy',
  CANNOT_STORE_TEAMS_LIMEADE_USER: 'Cannot store teams limeade user',
  CANNOT_STORE_USER_LOCALE: 'Cannot store user locale',
  CANNOT_FETCH_APP_CONFIG: 'Cannot fetch app configs',
  CANNOT_CHECK_CONSENT: 'Cannot check consent',
  CANNOT_GET_CONSENT: 'Cannot get consent',
  CANNOT_SIGN_CONSENT: 'Cannot sign consent',
  CANNOT_FETCH_FEATURE_FLAGS: 'Cannot fetch feature flags',
  CANNOT_GET_TEAMS: 'Cannot get teams',
  CANNOT_GET_AAD_USERS: 'Cannot get AAD Users', // No alert
  AUTH_ERROR: 'An error occurred when authentication',
  AUTH_USER_NOT_FOUND: 'The indicated user could not be found',
  AUTH_ACCESS_DENIED: 'Access denied', // No alert. Because no matching limeade account. it's customer data quality (not a system issue)
  CANNOT_GET_MICROSOFT_TEAMS_CONTEXT: 'Cannot get Microsoft teams context',
  CANNOT_GET_CRITERIA_ALERT: 'Cannot get criteria alert',
  CANNOT_GET_ALL_DEVICES_SYNC_INFO: 'Cannot get all device sync info',
};

export default ExceptionTypes;
