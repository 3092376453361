import { observable, action, makeObservable } from 'mobx';

import { RootStore } from './Root.store';
import { LevelData } from '../models/Level';
import { ChartData, LifeAreaData, RawLifeAreaData, SummaryData } from '../models/LifeArea';
import { ChartBarItemProps as ChartBarItem, getChartBarData } from '../components/shared/ChartBar';
import ApiWrapper from '../utilities/apiWrapper';
import { LifeAreaKey, LifeArea, PageSize } from '../utilities/constants';
import { isLevelCompleted } from '../utilities/levels';
import i18n from '../i18n';
import {
  MyPointResponse,
  PointsHistoryLevelRecord,
  PointsHistoryLevelItem,
  MyPointLoading,
} from 'src/models/PointHistory';
import LightPagination from 'src/lib/LightPagination/LightPagination';
import { PageableCollection } from 'src/lib/LightPagination/models';

export default class AchievementsStore {
  rootStore: RootStore;
  paginationManager = new Map<number, LightPagination>();
  isPointsHistoryFirstLoad = false;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable levels: LevelData[] = [];
  @observable lifeAreaEntries: LifeAreaData[] = [];
  @observable chartData: ChartData | undefined = undefined;
  @observable summaryData: SummaryData | undefined = undefined;
  @observable menuItems: LifeAreaKey[] | undefined = undefined;
  @observable activeLifeArea: LifeAreaKey | undefined = undefined;
  @observable currentColor: string | undefined = undefined;
  @observable assessmentIsCompleted: boolean = true;
  @observable overallWellBeing: number | undefined = undefined;
  @observable myPoint: MyPointResponse | undefined;
  @observable myPointLoading: MyPointLoading[] = [];
  @observable error?: Error;

  colors: string[] = ['#0F9112', '#008BC1', '#EA1047', '#0058A0', '#B20D00', '#EB6400', '#5F083A'];

  @action
  setError = (err: Error) => {
    this.error = err;
  };

  @action
  setLevels = (levels: LevelData[]): void => {
    this.levels = levels;
  };

  getLevelPoints = (level: LevelData) => {
    const levelPointsData = isLevelCompleted(level)
      ? { points: level.to_points, maxPoints: level.to_points }
      : { points: this.rootStore.UserProfileStore.profile?.points ?? 0, maxPoints: level.to_points };

    return i18n.t('achievementsPage.levelPointsStatus', levelPointsData);
  };

  get currentLevelInfo(): LevelData | null {
    const userPoints = this.rootStore.UserProfileStore.profile.points;
    for (let i = 0; i < this.levels?.length; i++) {
      if (userPoints >= this.levels[i].from_points && userPoints <= this.levels[i].to_points) {
        return this.levels[i];
      }
    }
    return null;
  }

  get chartDataItems(): ChartBarItem[] {
    if (!this.chartData) {
      return [];
    }
    return getChartBarData(this.chartData.categories, this.chartData.series);
  }

  @action
  setLifeAreaEntries = (entries: LifeAreaData[]): void => {
    this.lifeAreaEntries = entries;
    this.prepareSummaryData(entries);
  };

  @action
  handleChartChange = (lifeArea: LifeAreaKey): void => {
    const lifeAreaData = this.lifeAreaEntries.filter((item) => item.parent === LifeArea[lifeArea]);
    const result: ChartData = {
      lifeArea,
      categories: lifeAreaData.map((item) => {
        return item.title;
      }),
      series: lifeAreaData.map((item) => {
        return item.value;
      }),
    };
    this.currentColor = this.menuItems ? this.colors[this.menuItems.indexOf(lifeArea)] : this.colors[0];
    this.chartData = result;
    this.activeLifeArea = lifeArea;
  };

  @action
  prepareSummaryData = (entries: LifeAreaData[]): void => {
    const maxCategoryValue: number = 5;
    const result: SummaryData = { lifeAreas: [], defaultSeries: [], series: [] };
    const lifeAreas: LifeAreaKey[] = Object.keys(LifeArea) as LifeAreaKey[];

    for (const key of lifeAreas) {
      const categories = entries.filter((entry: LifeAreaData) => entry.parent === LifeArea[key]);
      if (categories.length) {
        result.lifeAreas.push(key);

        const averageValue = categories.reduce((sum, item) => sum + item.value, 0) / categories.length;
        result.defaultSeries.push(+averageValue.toFixed(2));

        const averageAreaValue: number = +(averageValue * (100 / maxCategoryValue)).toFixed(2);
        result.series.push(averageAreaValue);
      }
    }

    this.summaryData = result;
    this.menuItems = result.lifeAreas;
    this.handleChartChange(this.menuItems[0]);
  };

  @action
  updateLevels = async (): Promise<void> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const levels = await apiWrapper.getUserLevels();
    this.setLevels(levels.Data);
    return levels;
  };

  @action
  updateLifeAreaScores = async (): Promise<any> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const lifeAreaData = (await apiWrapper.getLifeAreaScores()).Data;

    this.setAssessmentIsCompleted(lifeAreaData.IsAssessmentCompleted);
    this.setOverallWellBeing(lifeAreaData.OverallWellBeing);

    const preparedData: LifeAreaData[] = lifeAreaData.LifeAreaScores.map((dataRow: RawLifeAreaData) => {
      return {
        title: dataRow.ScoreName,
        parent: dataRow.ScoreParent,
        value: +dataRow.ScoreValue,
      };
    });

    this.setLifeAreaEntries(preparedData);

    return lifeAreaData;
  };

  @action
  setAssessmentIsCompleted = (value: boolean): void => {
    this.assessmentIsCompleted = value;
  };

  @action
  setOverallWellBeing = (value: number): void => {
    this.overallWellBeing = value;
  };

  @action
  setMyPointLoading = (levelOrder: number, isLoading: boolean): void => {
    const foundLoadingIndex = this.myPointLoading.findIndex((loadingInfo) => loadingInfo.levelOrder === levelOrder);
    this.myPointLoading[foundLoadingIndex].isLoading = isLoading;
  };

  @action
  setPointHistoryLevelItems = (value: PointsHistoryLevelItem[]): void => {
    this.myPoint = {
      ...this.myPoint,
      PointsHistoryLevelItems: value,
    } as MyPointResponse;
  };

  showMorePointsHistoryByLevel = async (level: number) => {
    try {
      if (this.isPointsHistoryFirstLoad) {
        this.setMyPointLoading(level, true);
        const lightPagination = this.paginationManager.get(level);
        const foundLevelIndex = this.myPoint!.PointsHistoryLevelItems.findIndex(
          (levelItem) => levelItem.LevelOrder === level
        );
        const pointsHistoryLevelItems = [...this.myPoint!.PointsHistoryLevelItems];
        await lightPagination!.showMore(pointsHistoryLevelItems[foundLevelIndex].TotalCount);
        pointsHistoryLevelItems[foundLevelIndex].Items = lightPagination!.displayedItems;
        pointsHistoryLevelItems[foundLevelIndex].Items[PageSize.MY_POINTS_FIRST_LOAD].isFocus = true;
        this.setPointHistoryLevelItems(pointsHistoryLevelItems);
        this.setMyPointLoading(level, false);
      }
    } catch (error: any) {
      this.setError(error);
      throw error;
    }
  };

  showLessPointsHistoryByLevel = (level: number) => {
    try {
      if (this.isPointsHistoryFirstLoad) {
        const lightPagination = this.paginationManager.get(level);
        lightPagination!.showLess(PageSize.MY_POINTS_FIRST_LOAD);
        const foundLevelIndex = this.myPoint!.PointsHistoryLevelItems.findIndex(
          (levelItem) => levelItem.LevelOrder === level
        );
        const pointsHistoryLevelItems = [...this.myPoint!.PointsHistoryLevelItems];
        pointsHistoryLevelItems[foundLevelIndex].Items = lightPagination!.displayedItems;
        this.setPointHistoryLevelItems(pointsHistoryLevelItems);
      }
    } catch (error: any) {
      this.setError(error);
      throw error;
    }
  };

  firstLoadPointsHistory = async () => {
    try {
      const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
      const response = (await apiWrapper.getPointsHistory(null, '', PageSize.MY_POINTS_FIRST_LOAD)).Data;
      this.myPoint = {
        AreAllLevelsCompleted: response.AreAllLevelsCompleted,
        PointsHistoryLevelItems: response.PointsHistoryLevelItems,
      };
      this.isPointsHistoryFirstLoad = true;

      this.myPoint?.PointsHistoryLevelItems.forEach((levelItem) => {
        this.myPointLoading.push({
          isLoading: false,
          levelOrder: levelItem.LevelOrder,
        } as MyPointLoading);

        const pageableApi = async (pageSize: number, pagingToken: string | null) => {
          const result = await apiWrapper.getPointsHistory(levelItem.LevelOrder, pagingToken ?? '', pageSize);
          const phlItem: PointsHistoryLevelItem = result.Data!.PointsHistoryLevelItems[0];

          return {
            ...phlItem,
            items: phlItem.Items,
            pagingToken: phlItem.PagingToken,
            totalCount: phlItem.TotalCount,
          } as PageableCollection<PointsHistoryLevelRecord>;
        };

        const lightPagination = new LightPagination(pageableApi, levelItem.TotalCount);
        this.paginationManager.set(levelItem.LevelOrder, lightPagination);
      });
    } catch (error: any) {
      this.setError(error);
      throw error;
    }
  };

  getCriteriaAlerts = async () => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const response = await apiWrapper.getCriteriaAlert();
    return response.Data;
  };
}
