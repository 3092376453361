export interface ChartBarItemProps {
  title: string;
  value: number;
  announcement: string;
}

type Labels = string[];

export const getChartBarData = (labels: Labels, values: number[]): ChartBarItemProps[] => {
  const items: ChartBarItemProps[] = [];

  for (let i = 0; i < labels.length; i++) {
    items.push({
      title: labels[i],
      value: values[i],
      announcement: '',
    });
  }

  return items;
};
