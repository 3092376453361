import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { app as teamsjsApp } from '@microsoft/teams-js';

import { app } from './auth/TeamsJsWrapper';
import { TENANT_IDS_WITH_LANGUAGE_LIMITED } from './utilities/constants';
/**
 * If you need to add support for a new language just add it inside the supportedLngs array
 * and create a translation.json file then add this into a public/locales/{{language}} folder
 *
 * If you need a debug mode will be displayed in the web console than add a debug:true
 */

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: ['en'],
    supportedLngs: [
      'ar',
      'da',
      'de',
      'en',
      'en-GB',
      'es',
      'es-ES',
      'fr',
      'fr-CA',
      'hi',
      'hu',
      'id',
      'it',
      'ja',
      'ko',
      'nl',
      'no',
      'pl',
      'pt-PT',
      'pt-BR',
      'ro',
      'sv',
      'tr',
      'zh-CN',
    ],
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

app.getContext().then((context: teamsjsApp.Context) => {
  const tenantId = context?.user?.tenant?.id;
  // We still keep this logic here if we need this feature back in future.
  if (tenantId && TENANT_IDS_WITH_LANGUAGE_LIMITED.includes(tenantId)) {
    i18next.changeLanguage('en');
  } else {
    i18next.changeLanguage(context.app.locale);
  }
});

export default i18next;
