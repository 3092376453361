export enum RewardLevelStatus {
  NotStarted = 1,
  InProgress = 2,
  Completed = 3,
}

export interface Criteria {
  id: number;
  is_complete: boolean;
  name: string;
  order: number;
  url: string;
  points: number;
}

export interface CriteriaAlert {
  IncompleteCriteria: Criteria[];
  ShowIncompleteCriteria: boolean;
  LevelOrder: number;
  LevelStatus: RewardLevelStatus;
}

export interface LevelData {
  name: string;
  criteria?: Criteria[];
  from_points: number;
  level_completion_date: string;
  level_order: number;
  percent_completed: number;
  reward_text: string;
  to_points: number;
  status: RewardLevelStatus;
}
