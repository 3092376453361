const personStyles = {
  personAvatar: {
    display: 'flex',
    marginRight: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    height: '48px',
    width: '48px',
  },
  contactIcon: {
    fontSize: '24px',
  },
  personName: {
    fontSize: '14px',
    fontWeight: 600,
    whitespace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: '12px',
  },
  asterisk: {
    fontSize: '16px',
  },
};

export default personStyles;
