export enum DiscoverCategories {
  Featured = 'Featured',
  RecommendedByEmployer = 'RecommendedByEmployer',
  TopPicksForYou = 'TopPicksForYou',
  Wellbeing = 'Wellbeing',
  Trending = 'Trending',
  ManagerEngagement = 'ManagerEngagement',
  IndividualEngagement = 'IndividualEngagement',
  IndividualInclusion = 'IndividualInclusion',
}

export type DiscoverCategory = {
  readonly title: string;
  readonly tooltip?: string;
};

export const CategoryResourceStringKeys = {
  [DiscoverCategories.Featured]: {
    title: 'Featured',
    tooltip: 'Fow_CategoryFeaturedTooltip',
  },
  [DiscoverCategories.RecommendedByEmployer]: {
    title: 'Recommended',
    tooltip: 'Fow_CategoryRecommendedTooltip',
  },
  [DiscoverCategories.TopPicksForYou]: {
    title: 'TopPicks',
    tooltip: 'Fow_CategoryTopPicksTooltip',
  },
  [DiscoverCategories.Trending]: {
    title: 'TrendingNow',
    tooltip: 'Fow_CategoryTrendingTooltip',
  },
  [DiscoverCategories.Wellbeing]: {
    title: 'BasedOnWellBeingAssessment',
    tooltip: 'Fow_CategoryWellbeingTooltip',
  },
  [DiscoverCategories.IndividualEngagement]: {
    title: 'BasedOnEngagementSurvey',
  },
  [DiscoverCategories.IndividualInclusion]: {
    title: 'BasedOnInclusionSurvey',
  },
  [DiscoverCategories.ManagerEngagement]: {
    title: 'BuildTeamEngagement',
  },
};
