import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Header, Image, Text } from '@fluentui/react-northstar';
import encodeURL from 'encodeurl';

import styles from './OopsView.styles';
import { useStore } from 'src/stores/Root.store';
import pendoIdentifierBuilder from 'src/utilities/pendoIdentifierBuilder';
import ExceptionTypes from 'src/utilities/exceptionTypes';

type ErrorMessage = string;
type SessionId = string;

const resources = {
  subject: 'Limeade Teams app error',
  body: (errorMessage: ErrorMessage, sessionId: SessionId) =>
    `Error message: ${errorMessage}\nSession ID: ${sessionId}`,
};

interface IOopsViewProps {
  errorMessage: ErrorMessage;
  sessionId: SessionId;
}

const OopsView: React.FC<IOopsViewProps> = ({ errorMessage, sessionId }) => {
  const { t } = useTranslation();
  const { LocalizationStore } = useStore();

  const mailContent = `?subject=${encodeURL(resources.subject)}&body=${encodeURL(
    resources.body(errorMessage, sessionId)
  )}`;
  const emails = ['zach.dodds@limeade.com', 'thao.vu@limeade.com'];
  const mailto = emails.toString();
  const isMissingTenantAuthRecord = errorMessage.includes(ExceptionTypes.TENANT_AUTH_RECORD_MISSING); // isMissingTenantAuthRecord = true => this paywall message should show
  const isUserAccesDenied = errorMessage.includes(ExceptionTypes.AUTH_ACCESS_DENIED); // This user can install the Team App but he/she is not in the legal list to use this app. We do not send the alert for this exception
  const isUserNotFound = errorMessage.includes(ExceptionTypes.AUTH_USER_NOT_FOUND);

  const sendError = (): void => {
    window.location.href = `mailto:${mailto}${mailContent}`;
  };

  const getHeader = (): JSX.Element => {
    if (isUserAccesDenied || isMissingTenantAuthRecord || isUserNotFound) {
      return <Header as="h2" content={LocalizationStore.getString('Fow_OopsView_Header_Account_Issue')} />;
    }

    return <Header as="h2" content={t('errorView.headerTitle')} />;
  };

  const getErrorMessageBasedOnErrorType = () => {
    const limeadeDisplayUrl = 'www.limeade.com/company/contact-us';
    const limeadeUrl = `https://${limeadeDisplayUrl}`;
    const contactString = `<a href="${limeadeUrl}" size="large">${limeadeDisplayUrl}</a>`;

    if (isMissingTenantAuthRecord) {
      return LocalizationStore.getString('Fow_OopsView_Content_Tenant_Issue', contactString);
    }

    if (isUserAccesDenied || isUserNotFound) {
      return LocalizationStore.getString('Fow_OopsView_Content_User_Issue', contactString);
    }

    return t('errorView.content');
  };

  const getMessageContent = (): JSX.Element => {
    const messageContent = getErrorMessageBasedOnErrorType();
    return <div dangerouslySetInnerHTML={{ __html: messageContent.replace(/href=/g, "target='_blank' href=") }} />;
  };

  return (
    <Flex hAlign="center" styles={styles.wrapper}>
      <Flex column hAlign="center" styles={styles.oopsWrapper}>
        <Flex column hAlign="center">
          {getHeader()}
          <Image src="./oops-image.png" styles={styles.oopsImage} />
          <Text align="center" size="large" styles={styles.oopsMessage}>
            {getMessageContent()}
          </Text>
        </Flex>
        <Flex hAlign="center" styles={styles.oopsBtnContainer}>
          <Button
            data-insightId={pendoIdentifierBuilder({
              component: 'ErrorRefreshButton',
            })}
            content={t('buttons.refresh')}
            onClick={() => window.location.reload()}
          />
          <Button
            data-insightId={pendoIdentifierBuilder({
              component: 'ErrorSendButton',
            })}
            primary
            content={t('buttons.sendError')}
            onClick={sendError}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default memo(OopsView);
