import { observable, action, computed, makeObservable } from 'mobx';

import Queue from 'src/utilities/Queue';
import { TeamsActivity } from '../models/Activity';
import { RootStore } from './Root.store';
import { DiscoverCategories } from 'src/utilities/categories';

export default class CardStore {
  rootStore: RootStore;
  loaderQueue = new Queue();

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable appliedFilters: Array<(item: TeamsActivity) => boolean> = [];
  @observable isFirstLoading = true;
  @observable error: any = null;

  @computed get homeCards(): TeamsActivity[] {
    return this.rootStore.ActivityStore.homeActivities.filter((item) =>
      this.appliedFilters.every((filter) => filter(item))
    );
  }

  // This count can differ from ActivityStore.activities.size
  @computed get discoverCardCount() {
    let count = 0;
    Object.values(DiscoverCategories).forEach((category) => {
      count = count + (this.rootStore.ActivityStore.discoverPage[category].activities?.length ?? 0);
    });
    return count;
  }

  @action
  setAppliedFilters = (appliedFilters: Array<(item: TeamsActivity) => boolean>): void => {
    this.appliedFilters = appliedFilters;
  };

  @action
  setIsFirstLoading = (isLoading: boolean) => {
    this.isFirstLoading = isLoading;
  };

  @action
  setError = (error: any): void => {
    this.error = error;
  };
}
