import { observable, action, makeObservable } from 'mobx';

import ApiWrapper from 'src/utilities/apiWrapper';
import { TeamsActivity } from '../models/Activity';
import { FeatureFlags } from '../models/FeatureFlags';
import { isPrivacyNecessary } from '../utilities/activityUtilities';
import { TrackingMode, AppConfigs, RoutePath } from '../utilities/constants';
import { RootStore } from './Root.store';
import { AppConfig } from 'src/models/AppConfig';
import Logger from 'src/logger/Logger';

export default class ApplicationStore {
  rootStore: RootStore;
  trackedNotifications = new Set<string>();

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable pickedActivity?: TeamsActivity = undefined;
  @observable isPrivacyDialogOpen: boolean = false;
  @observable isDeviceRequiredDialogOpen: boolean = false;
  @observable restoringScrollActivityId?: string = undefined;
  @observable hasPrivacyPopupToJoinActivity: boolean = false;
  @observable featureFlags: FeatureFlags | undefined;
  @observable appConfigs?: Array<AppConfig>;

  handleJoinButtonClick = async (activity: TeamsActivity, history: any, isPrivate = true) => {
    const isActivityPersonal: boolean = !activity.socialTracking;
    const isDeviceRequired = activity.activityDetails.TrackingMode === TrackingMode.Device;

    if (isDeviceRequired && !this.rootStore.UserProfileStore.isDeviceConnected) {
      this.showDeviceRequiredDialog(activity);
      return;
    }

    if (!isActivityPersonal) {
      // go to join page
      // TODO: temp solution, needs to be refactor to not use history as parameter
      history.push(RoutePath.JoinTeam.replace(':id', activity.id));
    } else {
      if (isPrivacyNecessary(activity)) {
        await this.rootStore.ActivityStore.joinActivity(activity.id, activity.title, true);
      } else {
        if (!this.hasPrivacyPopupToJoinActivity) {
          await this.rootStore.ActivityStore.joinActivity(activity.id, activity.title, isPrivate, true);
        } else {
          this.showPrivacyDialog(activity);
        }
      }
    }
  };

  showPrivacyDialog = (activity: TeamsActivity): void => {
    this.setPickedActivity(activity);
    this.setShowPrivacyDialog(true);
  };

  showDeviceRequiredDialog = (activity: TeamsActivity): void => {
    this.setPickedActivity(activity);
    this.setDeviceRequiredDialog(true);
  };

  hidePrivacyDialog = (): void => {
    this.setShowPrivacyDialog(false);
  };

  hideDeviceRequiredDialog = (): void => {
    this.setDeviceRequiredDialog(false);
  };

  @action
  setPickedActivity = (activity: TeamsActivity): void => {
    this.pickedActivity = activity;
  };

  @action
  setShowPrivacyDialog = (value: boolean): void => {
    this.isPrivacyDialogOpen = value;
  };

  @action
  setDeviceRequiredDialog = (value: boolean): void => {
    this.isDeviceRequiredDialogOpen = value;
  };

  @action
  setRestoringScrollActivityId = (activityId: string): void => {
    this.restoringScrollActivityId = activityId;
  };

  @action
  resetRestoringScrollActivityId = (): void => {
    this.restoringScrollActivityId = undefined;
  };

  @action
  setHasPrivacyPopupToJoinActivity = (hasPrivacyPopupToJoinActivity: boolean): void => {
    this.hasPrivacyPopupToJoinActivity = hasPrivacyPopupToJoinActivity;
  };

  @action
  fetchFeatureFlags = async (features: string[]): Promise<void> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const data = await apiWrapper.fetchFeatureFlags(features);
    this.featureFlags = data;
  };

  @action
  fetchAppConfigs = async (): Promise<void> => {
    try {
      const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));

      const data = await apiWrapper.fetchAppConfigs();
      this.appConfigs = data || null;
    } catch (error) {
      // Don't interrupt User experience, just log it
      Logger.trackException(error);
    }
  };

  isTenantTurnedOffWba = async (tenantId: string | undefined): Promise<boolean> => {
    if (!tenantId) {
      return false;
    }

    if (this.appConfigs === undefined) {
      await this.fetchAppConfigs();
    }

    const turnOffWbaConfig = this.appConfigs?.find((config) => config.key === AppConfigs.TURNOFFWBA);
    const isTurnOff = !!turnOffWbaConfig?.value.split(',').find((item: string) => {
      return item.trim() === tenantId.trim();
    });

    return isTurnOff;
  };

  /**
   * Add clicked notification ID for tracking
   * @param correlationId string
   */
  trackClickedNotification = (correlationId: string) => {
    this.trackedNotifications.add(correlationId);
  };

  /**
   * Verify if a notification has been clicked
   * @param correlationId string
   */
  checkNotificationClicked = (correlationId: string) => {
    return this.trackedNotifications.has(correlationId);
  };
}
