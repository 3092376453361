import { observer } from 'mobx-react-lite';
import { isMobile } from 'react-device-detect';
import { VisuallyHidden } from '@reach/visually-hidden';
import { InitializeTabsData } from './InitializeTabsData';
import LimeadeLoader from '../components/shared/Loader/Loader';
import { useStore } from '../stores/Root.store';
import LoginView from '../components/views/LoginView';
import LogoutView from '../components/views/LogoutView';
import Logger from '../logger/Logger';
import { isAuthorization } from 'src/utilities/utilities';

export const ProtectedRoute = observer((opts: { path: string; component: any; requiresAuthentication: boolean }) => {
  const { AppAuthStore, LocalizationStore } = useStore();

  const isAuthenticated = AppAuthStore.isAuthenticated;
  const isLoading = AppAuthStore.isLoading;
  const isSwitchUser = AppAuthStore.isSwitchUser;
  const showLoginPage = opts.requiresAuthentication && !isAuthenticated;
  const shouldRenderVisuallyHidden = !isMobile;

  if (isLoading) {
    return <LimeadeLoader />;
  }

  if (isSwitchUser && !isAuthorization()) {
    const userEmailFoW = AppAuthStore.authorizedTeamsUserId; // email of the user login to FoW
    const userEmailTeam = AppAuthStore.teamsUserState.teamsUser?.preferredUserName; // email of the user login to MS Team
    Logger.logMessage(`Different account detected. FOW user email: ${userEmailFoW}. Team user email: ${userEmailTeam}`);

    return <LogoutView from={opts.path} />;
  }

  if (showLoginPage) {
    return <LoginView from={opts.path} />;
  }

  return (
    <>
      {shouldRenderVisuallyHidden && (
        <VisuallyHidden>
          <p>{LocalizationStore.getString('Fow_SkipToMainContent')}</p>
        </VisuallyHidden>
      )}
      <InitializeTabsData path={opts.path} component={opts.component} />
    </>
  );
});
