import { Criteria, CriteriaAlert, LevelData, RewardLevelStatus } from '../models/Level';

export function isLevelCompleted(levelData: LevelData): boolean {
  return levelData.status === RewardLevelStatus.Completed;
}

export function isAllLevelsCompleted(levelsData: LevelData[]): boolean {
  return !!levelsData?.length && isLevelCompleted(levelsData[levelsData.length - 1]);
}

export function getCurrentlevelInfo(levelsData: LevelData[], defaultValue: string): LevelData {
  const data = levelsData.find((levelData) => levelData.status === RewardLevelStatus.InProgress);

  if (!data) {
    return levelsData[levelsData.length - 1];
  }

  if (data.name === '') {
    data.name = defaultValue;
  }

  return data;
}

export function getCurrentCriteria(criteriaAlerts?: CriteriaAlert[]): Criteria[] | null {
  const criteriaAlert = criteriaAlerts?.find(
    (item) => item.ShowIncompleteCriteria && item.LevelStatus === RewardLevelStatus.InProgress
  );

  return criteriaAlert?.IncompleteCriteria ?? null;
}
