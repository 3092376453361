import { getFormattedLimeadeDateInLocalTime } from '@limeade/ux-utilities';
import { getPlural } from '@limeade/ux-cms';
import { TrackingType, UserChallengeFrequency, UserChallengeStatus, ActivityUnit } from './constants';
import i18n from '../i18n';

import { TeamsActivity, TrackingStrategy } from '../models/Activity';
import ActivityDetails, { ActionData } from '../models/ActivityDetails';
import { IActivityProgress } from '../components/shared/ActivityProgress';

export function isCompleted(activity: TeamsActivity): boolean {
  return (activity?.activityDetails?.Status & UserChallengeStatus.Completed) === UserChallengeStatus.Completed;
}

export function isJoined(activity: TeamsActivity): boolean {
  return (activity?.activityDetails?.Status & UserChallengeStatus.InProgress) === UserChallengeStatus.InProgress;
}

export function isActivityTracked(activity: ActivityDetails, trackingType: TrackingType, date: Date): boolean {
  const progressIsEmpty = !activity?.Progress || activity.Progress.length === 0;
  if (progressIsEmpty) {
    return false;
  }

  const isAllNumbersType = trackingType === TrackingType.ADD_ALL_NUMBERS;
  if (isAllNumbersType) {
    return false;
  }

  const formattedDateInLocalTime = getFormattedLimeadeDateInLocalTime(date, 'YYYY-MM-DD', {}, 'en' as any);
  const isTrackedDay = activity.Actions[0].Data.some(
    (actionData: ActionData) => actionData.Value > 0 && actionData.Date === formattedDateInLocalTime
  );

  return isTrackedDay;
}

/**
 * Gets progress of activity
 * @param activity the object of the {@link TeamsActivity}.
 * @returns {@link IActivityProgress} object that represend the current status of activity
 */
export function getActivityProgress(
  activity: TeamsActivity,
  week: number = 0,
  isActivityCard: boolean = false
): IActivityProgress {
  const details = activity.activityDetails;
  const progress = {
    earnedPoints: 0,
    totalPoints: details?.TargetGoal,
    amountUnit: details?.DispUnit,
    trackingStrategy: activity.trackingStrategy,
    trackingType: activity.trackingType,
    isActivityCard,
  };

  if (!details?.Progress || details.Progress.length === 0) {
    return progress;
  }

  // ADD_ALL_NUMBERS with no frequency
  if (
    activity.trackingType === TrackingType.ADD_ALL_NUMBERS &&
    details.Frequency === UserChallengeFrequency.SpecificDate
  ) {
    progress.earnedPoints = details.IsTeamChallenge ? details.Progress[0].TeamValue : details.Progress[0].Value;

    return progress;
  }

  if (details.Frequency === UserChallengeFrequency.Weekly) {
    if (week > 0) {
      week = 0;
    }

    if (-week > details.Progress.length - 1) {
      return progress;
    }

    progress.earnedPoints = details.Progress[details.Progress.length + week - 1].Value;

    return progress;
  }

  progress.earnedPoints = details.Progress[details.Progress.length - 1].Value;

  return progress;
}

export function isPrivacyNecessary(activity: TeamsActivity): boolean {
  const isPartnerOrListening =
    activity.activityDetails?.IsListeningToolsChallenge || activity.activityDetails?.IsPartnerVerifiedChallenge;

  return !activity.socialTracking && (activity.trackingStrategy.defaultPrivacy === 'self' || isPartnerOrListening);
}

export function pluralize(amount: number, activityUnit: string) {
  activityUnit = activityUnit.toLocaleLowerCase();
  if (Object.values(ActivityUnit).includes(activityUnit as ActivityUnit)) {
    return i18n.t(`activityUnit.${activityUnit}`, { count: amount });
  }
  return `${amount} ${activityUnit}`.trim();
}

export function getLocaleUnit(activityUnit: string) {
  return pluralize(0, activityUnit).replace('0', '').trim();
}

export function buildGoalContent(trackingStrategy: TrackingStrategy, trackingType: TrackingType) {
  const isYesNoDaily: boolean = trackingType === TrackingType.YES_NO_DAILY;

  if (isYesNoDaily) {
    return buildGoalContentForYesNoDaily(trackingStrategy.goal, trackingStrategy.frequency);
  }

  return trackingStrategy.targetDescription;
}

export function buildGoalContentForYesNoDaily(goal: string, frequency: string) {
  const goalAsNumber = Number.parseInt(goal);

  return frequency === UserChallengeFrequency[UserChallengeFrequency.Weekly]
    ? getPlural(ActivityUnit.TIMES_WEEKLY, goalAsNumber, i18n.language)
    : getPlural(ActivityUnit.TIME, goalAsNumber, i18n.language);
}
