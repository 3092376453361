import React, { useEffect, useState } from 'react';
import { CMSContextProvider, getResources } from '@limeade/ux-cms';

import { TENANT_IDS_WITH_LANGUAGE_LIMITED } from 'src/utilities/constants';
import { useStore } from 'src/stores/Root.store';
import { app } from 'src/auth/TeamsJsWrapper';

export interface CmsContextProviderOptions {
  children?: React.ReactNode;
}

const CmsContextProviderLoader: React.FC<CmsContextProviderOptions> = (opts: CmsContextProviderOptions) => {
  const { children } = opts;
  const { UserProfileStore } = useStore();
  const [language, setLanguage] = useState('');
  const { LocalizationStore } = useStore();

  useEffect(() => {
    const loadContext = () => {
      app.getContext().then((context) => {
        const tenantId = context.user?.tenant?.id;
        // We still keep this logic here if we need this feature back in future.
        if (tenantId && TENANT_IDS_WITH_LANGUAGE_LIMITED.includes(tenantId)) {
          setLanguage('en');
        } else {
          setLanguage(context.app.locale);
        }
      });
    };

    loadContext();
  }, [UserProfileStore]);

  const loadCmsContextProvider = () => {
    if (language) {
      const mappingLanguage = LocalizationStore.getLanguageMappingFromMsToCms(language);
      LocalizationStore.setLanguage(mappingLanguage);
      const cmsContextValue = getResources(mappingLanguage);
      return <CMSContextProvider value={cmsContextValue}>{children}</CMSContextProvider>;
    }

    return null;
  };

  return loadCmsContextProvider();
};

export default CmsContextProviderLoader;
