export default class Queue {
  queue: any[] = [];

  enqueue(element) {
    return this.queue.push(element);
  }

  dequeue() {
    if (this.queue.length > 0) {
      return this.queue.shift(); // remove first element
    }
  }

  peek() {
    return this.queue[0];
  }

  size() {
    return this.queue.length;
  }

  isEmpty() {
    return this.queue.length === 0;
  }

  clear() {
    this.queue = [];
  }
}
