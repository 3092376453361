import { action, computed, makeObservable, observable } from 'mobx';
import { User as Auth0User } from '@auth0/auth0-react';
import { User as FGUser, WebStorageStateStore } from 'oidc-client-ts';

import { RootStore } from './Root.store';
import { LimeadeAuthStates, LIMEADE_ROLE, STORED_USER_MAPPING, TeamsUserStates } from '../utilities/constants';
import { LimeadeAuthState } from '../auth/LimeadeAuthState';
import { LimeadeAuthMethods } from '../auth/LimeadeAuthMethods';
import { initialLimeadeAuthMethods } from '../auth/LimeadeAuthContext';
import { TeamsUserState } from '../auth/TeamsUserState';
import ApiWrapper from '../utilities/apiWrapper';
import { TeamsLimeadeUserMapping } from 'src/models/TeamsLimeadeUserMapping';
import Logger from '../logger/Logger';
import Config from 'src/Config';
import ExceptionTypes from 'src/utilities/exceptionTypes';

type AccessTokenGetter = () => Promise<string | null>;
const AuthorizedTeamsUserIdStorageKey = 'AuthorizedTeamsUserId';
const AuthConfigStorageKey = 'AuthConfig';

export default class AppAuthStore {
  rootStore: RootStore;

  webStorageStateStore = new WebStorageStateStore({ store: window.localStorage });

  authorizedTeamsUserIdLoaded: boolean = false;
  authorizedTeamsUserIdPromise: Promise<string | null>;

  authConfigLoaded: boolean = false;
  authConfigPromise: Promise<any | null>;

  resolveTeamsUserStatePromise = (value: any) => {};
  rejectTeamsUserStatePromise = (error: any) => {};
  teamsUserStatePromise: Promise<TeamsUserState> = new Promise((resolve, reject) => {
    this.resolveTeamsUserStatePromise = resolve;
    this.rejectTeamsUserStatePromise = reject;
  });

  resolveLimeadeAuthStatePromise = (value: any) => {};
  rejectLimeadeAuthStatePromise = (error: any) => {};
  limeadeAuthStatePromise: Promise<any /* LimeadeAuthState<Auth0User | FGUser> */> = new Promise((resolve, reject) => {
    this.resolveLimeadeAuthStatePromise = resolve;
    this.rejectLimeadeAuthStatePromise = reject;
  });

  isLoadingPromiseList: Array<Promise<any>> = [];

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;

    this.authorizedTeamsUserIdPromise = this.webStorageStateStore.get(AuthorizedTeamsUserIdStorageKey);
    this.authorizedTeamsUserIdPromise.then((authorizedTeamsUserId) => {
      this.authorizedTeamsUserId = authorizedTeamsUserId;
      this.authorizedTeamsUserIdLoaded = true;
    });

    this.authConfigPromise = new Promise(async (resolve) => {
      const cacheAuthConfigJson = await this.webStorageStateStore.get(AuthConfigStorageKey);
      const cacheAuthConfig = cacheAuthConfigJson ? JSON.parse(cacheAuthConfigJson) : null;

      if (this.checkAuthConfigValid(cacheAuthConfig)) {
        this._authConfig = cacheAuthConfig;
      }

      this.authConfigLoaded = true;
      resolve(this._authConfig);
    });

    this.isLoadingPromiseList.push(this.authorizedTeamsUserIdPromise);
    this.isLoadingPromiseList.push(this.authConfigPromise);
    this.isLoadingPromiseList.push(this.teamsUserStatePromise);
    this.isLoadingPromiseList.push(this.limeadeAuthStatePromise);
  }

  @observable authorizedTeamsUserId: string | null | undefined;
  @observable authorizedLimeadeUserId: number | null | undefined;
  @observable limeadeAuthMethods: LimeadeAuthMethods = initialLimeadeAuthMethods;
  @observable limeadeAuthState: LimeadeAuthState<Auth0User> | LimeadeAuthState<FGUser> = LimeadeAuthStates.Anonymous;
  @observable teamsUserState: TeamsUserState = TeamsUserStates.NotInTeams;
  @observable isProviderLoaded: boolean = false;
  @observable _authConfig: any = null;
  @observable _accessToken: string = '';

  // Do not use this directly as we should check auth state validity before providing token.
  @observable _accessTokenGetter: AccessTokenGetter = () => Promise.resolve(null);

  @observable removeUserCallback: () => void = () => {};

  @computed
  get authConfig() {
    return this._authConfig?.record;
  }

  isStoredAuthConfigValid = (): boolean => {
    return this.checkAuthConfigValid(this._authConfig);
  };

  checkAuthConfigValid = (authConfig: any): boolean => {
    const authConfigRecord = authConfig?.record;
    return (
      !!authConfigRecord?.clientId &&
      !!authConfigRecord?.limeadeDomain &&
      !!authConfigRecord?.authority &&
      !!authConfigRecord?.organizationId &&
      !!authConfigRecord?.audience &&
      authConfig?.expiredAt > Date.now()
    );
  };

  fetchAuthConfig = async (aadTenantId: string): Promise<any> => {
    let authConfigRecord: any = {
      authType: 'AuthV3',
    };

    // Wait for teams user before getting auth config.
    await this.teamsUserStatePromise;

    // Wait auth config.
    await this.authConfigPromise;

    // We do not need token when getting employer settings.
    const api = new ApiWrapper(() => Promise.resolve(''));

    if (this.isStoredAuthConfigValid()) {
      // Use stored value if available and valid.
      authConfigRecord = this._authConfig.record;
    } else {
      authConfigRecord = await api.getAuthConfigByAADTenantId(aadTenantId);
      const cycle = +Config.authConfigCycleMinutes * 60 * 1000;
      const authConfig = {
        record: authConfigRecord,
        expiredAt: Date.now() + cycle,
      };
      const isAuthConfigValid = this.checkAuthConfigValid(authConfig);

      if (isAuthConfigValid) {
        this.setAuthConfig(authConfig);
      } else {
        throw new Error(ExceptionTypes.AUTH_CONFIG_FROM_API_INVALID);
      }
    }

    return authConfigRecord;
  };

  setAuthConfig = (value: any) => {
    this.webStorageStateStore.set(AuthConfigStorageKey, JSON.stringify(value));
    this._authConfig = value;
  };

  @action
  setIsProviderLoaded = (value: boolean) => {
    this.isProviderLoaded = value;
  };

  @action
  setLimeadeAuthMethods = (value: LimeadeAuthMethods): void => {
    this.limeadeAuthMethods = value;
  };

  @action
  setLimeadeAuthState = (value: LimeadeAuthState<Auth0User> | LimeadeAuthState<FGUser>): void => {
    this.limeadeAuthState = value;

    if (!value.isLoading) {
      value.error ? this.rejectLimeadeAuthStatePromise(value.error) : this.resolveLimeadeAuthStatePromise(value);
    }
  };

  @action
  resetLimeadeAuthState = (): void => {
    this.limeadeAuthState = LimeadeAuthStates.Anonymous;
  };

  @action
  setLimeadeAuthIsLoading = (value: boolean): void => {
    this.limeadeAuthState.isLoading = value;
  };

  @computed
  get limeadeDomain(): string {
    return this.authConfig?.limeadeDomain;
  }

  @computed
  get aadTenantId(): string | undefined {
    return this.teamsUserState?.teamsUser?.tenantId;
  }

  @computed
  get accessToken() {
    if (!this._accessToken) {
      this.getAccessToken();
    }
    return this._accessToken;
  }

  async getAccessToken(): Promise<string | null> {
    if (this.isLoading) {
      await Promise.all(this.isLoadingPromiseList);
      return this.getAccessToken();
    }

    if (this.isValid) {
      const token = await this._accessTokenGetter();
      this._accessToken = token ?? '';
      return token;
    }

    return null;
  }

  @computed
  get isAuthenticated() {
    // User is considered as authenticated when he or she is authenticated
    return this.limeadeAuthState.isAuthenticated;
  }

  @computed
  get isSwitchUser() {
    if (
      !this.isLoading &&
      this.isAuthenticated &&
      this.teamsUserState.inTeams &&
      this.authorizedTeamsUserId &&
      this.authorizedTeamsUserId.toLowerCase() !== this.teamsUserState.teamsUser?.preferredUserName?.toLowerCase()
    ) {
      return true;
    }
    return false;
  }

  @computed
  get isLoading() {
    return (
      !this.isProviderLoaded ||
      this.limeadeAuthState.isLoading ||
      this.teamsUserState.isLoading ||
      !this.authorizedTeamsUserIdLoaded
    );
  }

  @computed
  get isValid() {
    if (this.isLoading) {
      return false;
    }

    if (!this.isAuthenticated) {
      return false;
    }

    return true;
  }

  @computed
  get userRoles(): Array<string> {
    if (
      this.isValid &&
      this.limeadeAuthState.limeadeUser &&
      this.limeadeAuthState.limeadeUser.hasOwnProperty(LIMEADE_ROLE)
    ) {
      return this.limeadeAuthState.limeadeUser[LIMEADE_ROLE];
    }

    return [];
  }

  @action
  setTeamsUserState = (value: TeamsUserState): void => {
    this.teamsUserState = value;

    if (!value.isLoading) {
      if (value.error) {
        this.rejectTeamsUserStatePromise(value.error);
        //throw value.error;
      } else {
        this.resolveTeamsUserStatePromise(value);
      }
    }
  };

  @action
  setAuthotrizedTeamsUserId = (value: string): void => {
    this.webStorageStateStore.set(AuthorizedTeamsUserIdStorageKey, value);
    this.authorizedTeamsUserId = value;
  };

  @action
  setAuthorizedLimeadeUserId = (value: number): void => {
    this.authorizedLimeadeUserId = value;
  };

  @action
  resetAuthConfig = (): void => {
    this.webStorageStateStore.remove(AuthConfigStorageKey);
    this._authConfig = null;
  };

  @action
  resetAuthotrizedTeamsUserId = (): void => {
    this.webStorageStateStore.remove(AuthorizedTeamsUserIdStorageKey);
    this.authorizedTeamsUserId = null;
  };

  @action
  setAccessTokenGetter = (value: AccessTokenGetter): void => {
    this._accessTokenGetter = value;
  };

  storeTeamsLimeadeUser = async (value: TeamsLimeadeUserMapping): Promise<void> => {
    const key = `${STORED_USER_MAPPING}_${value.limeadeUserId}`;
    const cycle = +Config.storeMappingCycleMinutes * 60 * 1000;
    const storeInfo = {
      isStored: true,
      expiredAt: Date.now() + cycle,
    };
    this.setAuthorizedLimeadeUserId(value.limeadeUserId);

    try {
      const apiWrapper = new ApiWrapper(this.getAccessToken);
      const data = JSON.parse((await this.webStorageStateStore.get(key)) || '{}');

      if (!data?.isStored || data?.expiredAt <= Date.now()) {
        const requiredKeys = ['upn', 'oid', 'aadId', 'limeadeUserId', 'limeadeTenantId'];
        const isMappingInvalid = requiredKeys.some((key) => value[key] === null || value[key] === undefined);

        if (isMappingInvalid) {
          const mappingInfo = {
            ...value,
            upn: String(value.upn),
            oid: String(value.oid),
            aadId: String(value.aadId),
            limeadeUserId: String(value.limeadeUserId),
            limeadeTenantId: String(value.limeadeTenantId),
          };
          throw new Error(ExceptionTypes.USER_MAPPING_INVALID, {
            cause: new Error(`invalid data: ${JSON.stringify(mappingInfo)}`),
          });
        }

        await apiWrapper.storeTeamsLimeadeUser(value);
        this.webStorageStateStore.set(key, JSON.stringify(storeInfo));
      }
    } catch (error: any) {
      // This is special case that we must write log here
      Logger.trackException(error);
    }
  };
}
