import { getResources, stringFormat } from '@limeade/ux-cms';
import { computed } from 'mobx';

import { RootStore } from './Root.store';

export default class LocalizationStore {
  rootStore: RootStore;
  language = 'en';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get getCurrentLanguage() {
    return this.language;
  }

  setLanguage = (lang: string) => {
    this.language = lang;
  };

  getLanguageMappingFromMsToCms = (msLang: string): string => {
    if (msLang.startsWith('no') || msLang.startsWith('nn')) {
      return 'nb';
    }

    if (msLang.startsWith('es')) {
      if (msLang.split('-')[1] === 'es') {
        return 'es';
      }

      return 'es-la';
    }

    return msLang;
  };

  getString = (key: string, ...replacements: string[]) => {
    const resources = getResources(this.language);
    const text = resources.strings[key];
    if (!!text && replacements?.length) {
      return stringFormat(text, ...replacements);
    }
    return text;
  };
}
