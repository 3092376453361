import { Flex, Text, Image } from '@fluentui/react-northstar';
import { isMobile } from 'react-device-detect';

import { useStore } from 'src/stores/Root.store';
import styles from './ProgramQuietTime.styles';

const ProgramQuietTime = () => {
  const { LocalizationStore } = useStore();
  const pqtTitle = LocalizationStore.getString('Fow_Pqt_Part1');
  const pqtMessage = LocalizationStore.getString('Fow_Pqt_Part3');
  const imageLink = isMobile ? './llustration-PQT-mobile.svg' : './llustration-PQT.svg';

  return (
    <Flex hAlign="center" styles={styles.wrapper}>
      <Flex column hAlign="center" styles={styles.pqtWrapper}>
        <Flex column hAlign="center">
          <Image src={imageLink} styles={styles.pqtImage} />
          <Text content={pqtTitle} align="center" size="large" styles={styles.pqtTitle} />
          <Text content={pqtMessage} align="center" size="large" styles={styles.pqtMessage} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProgramQuietTime;
