import { DateTime } from 'luxon';
import {
  addDays,
  differenceInWeeks,
  startOfWeek,
  endOfWeek,
  subWeeks,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
} from 'date-fns';
import { unescape } from 'html-escaper';
import { getFormattedLimeadeDateInLocalTime } from '@limeade/ux-utilities';

import { ColorTheme, LifeAreaKey, MgtClassName, LIMEADE_EXT_UPN, LIMEADE_EXT_OID } from './constants';
import ExceptionTypes from './exceptionTypes';

export interface ActivityInterface {
  startDate: any; // number;
  trackedDays: any; // Array<Date>;
}

export interface CalendarDateItem {
  date: Date;
  day: any;
  weekDay: any;
  valid: boolean;
  highlighted: boolean;
}

export type CalendarData = { calendarDates: Array<CalendarDateItem>; todayIndex: number; prevWeekCount: number };
export const emptyCalendarData: CalendarData = { calendarDates: [], todayIndex: 0, prevWeekCount: 1 };

export const getLocalFormattedDate = (date: Date, locale: string) => {
  const luxonDate = DateTime.fromJSDate(date);
  const localDate = luxonDate.setLocale(locale);
  return localDate.toLocaleString(DateTime.DATE_FULL);
};

export const getWeekDay = (date: Date, locale: string) => {
  const luxonDate = DateTime.fromJSDate(date);
  const localDate = luxonDate.setLocale(locale);
  return localDate.weekdayLong;
};

export const getCalendarData = (activity: ActivityInterface | null, locale: any): CalendarData => {
  if (activity == null) {
    return emptyCalendarData;
  }

  const maxWeeks = 2; // Maximum weeks to go back.
  const today = new Date();
  const start = new Date(activity.startDate);
  const diffWeeksInStartDate = differenceInWeeks(today, startOfWeek(start));
  const prevWeekCount = diffWeeksInStartDate >= 2 ? maxWeeks : diffWeeksInStartDate;
  const startOfDays = startOfWeek(subWeeks(today, prevWeekCount), { weekStartsOn: 1 });
  const endOfDays = endOfWeek(today, { weekStartsOn: 1 });

  const dayArray: Array<CalendarDateItem> = [];
  let tabIndex = 0;
  let day = startOfDays;

  let i = 0;
  while (day <= endOfDays) {
    const currentDay = day;
    const dateItem = {
      date: day, // Actual js date object.
      day: getFormattedLimeadeDateInLocalTime(day, 'D', null, locale), // Used for day display.
      weekDay: getFormattedLimeadeDateInLocalTime(day, 'ddd', null, locale), // Used for week day display.
      valid: isAfter(today, day) && !isBefore(day, start), // Used to determine if selectable.
      highlighted: !!activity.trackedDays?.find((d: any) => isSameDay(currentDay, d.date)), // Checkmark if highlighted.
    };

    dayArray.push(dateItem);
    if (isToday(day)) {
      tabIndex = i;
    }

    day = addDays(day, 1);
    i++;
  }

  return {
    calendarDates: dayArray,
    todayIndex: tabIndex,
    prevWeekCount,
  };
};

export const getIsPrivateServerValue = (isPrivate: boolean): number => (isPrivate ? 1 : 0);

export function guidToInt(guid: string): number {
  return +guid.replace(/-/g, '');
}

export function roundNumber(input: number) {
  return parseFloat(input.toFixed(1));
}

export function getLimeadeUrl(limeadeDomain: string, relativePath?: string): string {
  if (!limeadeDomain) {
    throw new Error(ExceptionTypes.LIMEADE_DOMAIN_NOT_FOUND);
  }

  if (relativePath) {
    return new URL(relativePath, limeadeDomain).href;
  }
  return limeadeDomain;
}

export function openLimeadeWebsite(limeadeDomain: string | undefined | null, activityId: string) {
  if (!limeadeDomain) {
    throw new Error(ExceptionTypes.LIMEADE_DOMAIN_NOT_FOUND);
  }

  window.open(getLimeadeUrl(limeadeDomain, `/home/?cid=${guidToInt(activityId)}`), '_blank', 'noopener,noreferrer');
}

export function openLimeadeAssessmentPage(limeadeDomain: string | undefined | null) {
  if (!limeadeDomain) {
    throw new Error(ExceptionTypes.LIMEADE_DOMAIN_NOT_FOUND);
  }

  window.open(getLimeadeUrl(limeadeDomain, `/MyResults/default.aspx`), '_blank', 'noopener,noreferrer');
}

export function getLimeadeAssessmentPage(limeadeDomain: string | undefined | null): string {
  if (!limeadeDomain) {
    throw new Error(ExceptionTypes.LIMEADE_DOMAIN_NOT_FOUND);
  }

  return getLimeadeUrl(limeadeDomain, `/MyResults/default.aspx`);
}

export function openDeviceConnection(limeadeDomain: string | undefined | null) {
  if (!limeadeDomain) {
    throw new Error(ExceptionTypes.LIMEADE_DOMAIN_NOT_FOUND);
  }

  window.open(getLimeadeUrl(limeadeDomain, `/user/settings.aspx?tab=6`), '_blank', 'noopener,noreferrer');
}

export function openExternalWebsite(limeadeDomain: string | undefined | null, url: string) {
  if (!isUrlAbsolute(url) && limeadeDomain) {
    url = getLimeadeUrl(limeadeDomain, url);
  }
  window.open(url, '_blank', 'noopener,noreferrer');
}

export function isUrlAbsolute(url: string): boolean {
  const regExp = new RegExp('^(?:[a-z]+:)?//', 'i');
  return regExp.test(url);
}

export function htmlDecode(input: string): string {
  const decodedString = unescape(input);
  return decodedString.trim();
}
/**
 * Gets view of activity for pendo telemetry.
 * @returns string that represent the view name
 */
export function currentView(): 'Card' | 'Detail' {
  const url = window.location.origin;

  if (url.includes('activity')) {
    return 'Detail';
  }

  return 'Card';
}

export function getMgtClassName(themeName: ColorTheme): MgtClassName {
  if (themeName === ColorTheme.DEFAULT) {
    return MgtClassName.LIGHT;
  }
  return MgtClassName.DARK;
}

export function getLifeAreaTranslationKey(lifeAreaKey: LifeAreaKey): string {
  return `achievementsPage.lifeArea.${lifeAreaKey}`;
}

export function isAuthorization(): boolean {
  let url = window.location.href.toLowerCase();
  return (
    url.includes('fglogin') ||
    url.includes('auth0login') ||
    url.includes('logincallback') ||
    url.includes('auth0logout') ||
    url.includes('auth0logoutcallback')
  );
}

export function debounce(func: Function, timeout = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export interface Auth0UserResult {
  exceptionType: string | undefined;
  exceptionDetail: string | undefined;
  user: any;
}

export function checkAuth0User(user: any): Auth0UserResult {
  const result: Auth0UserResult = {
    exceptionType: undefined,
    exceptionDetail: undefined,
    user: undefined,
  };

  if (!user) {
    result.exceptionType = ExceptionTypes.AUTH_ERROR;
    result.exceptionDetail = 'The indicated user could not be found';
    return result;
  }

  if (!user[LIMEADE_EXT_UPN] || !user[LIMEADE_EXT_OID]) {
    result.exceptionType = ExceptionTypes.AUTH_ERROR;
    result.exceptionDetail = `The user principle name and object id could not be found. Invalid data: ${JSON.stringify(
      user
    )}.`;
    return result;
  }
  result.user = user;
  return result;
}
