export const privacyDialogStyles = {
  dialog: {
    width: '360px',
    borderRadius: '4px',
  },
  header: {
    fontSize: '18px',
    lineHeight: '24px',
  },
  text: {
    fontSize: '14px',
    lineHeight: '20px',
    whiteSpace: 'pre-line',
    margin: '12px 0',
  },
  checkbox: {
    marginBottom: '20px',
    padding: 0,
  },
};

export default privacyDialogStyles;
