import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import SplashScreen from 'src/components/shared/SplashScreen/SplashScreen';
import { useStore } from 'src/stores/Root.store';
import LoginButton from 'src/auth/LoginButton/LoginButton';

interface LoginViewProps {
  from: string;
}

const LoginView: React.FC<LoginViewProps> = observer(({ from }) => {
  const { AppAuthStore } = useStore();
  const authError = AppAuthStore.limeadeAuthState.error || AppAuthStore.teamsUserState.error;

  if (!!authError) {
    throw authError;
  }

  if (!AppAuthStore.isAuthenticated) {
    return (
      <SplashScreen>
        <LoginButton />
      </SplashScreen>
    );
  }

  const redirectPath = from ?? '/home';
  return <Redirect to={redirectPath} />;
});

export default LoginView;
