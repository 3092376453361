import React from 'react';
import { observer } from 'mobx-react-lite';
import LimeadeLoader from 'src/components/shared/Loader/Loader';
import { useStore } from 'src/stores/Root.store';

interface IRootPaneProps {
  children: React.ReactNode | React.ReactNode[];
}

const RootPane: React.FC<IRootPaneProps> = observer(({ children }) => {
  const { AppAuthStore } = useStore();
  const limeadeLoader = AppAuthStore.isLoading && <LimeadeLoader />;

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      {limeadeLoader}
      <div hidden={AppAuthStore.isLoading}>{children}</div>
    </div>
  );
});

export default RootPane;
