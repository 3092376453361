export const deviceRequiredDialogStyles = {
  header: {
    fontSize: '18px',
    lineHeight: '24px',
  },
  text: {
    fontSize: '14px',
    lineHeight: '20px',
    whiteSpace: 'pre-line',
    margin: '12px 0',
  },
  icon: {
    fontSize: '20px',
    color: 'orange',
  },
};

export default deviceRequiredDialogStyles;
