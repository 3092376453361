import { DiscoverActivityTypes, Privacy, TrackingType, UserChallengeFrequency } from './constants';
import { DiscoverCategories } from './categories';

type SocialTrackingType = 'Team' | 'Self';
type ToggleState = 'On' | 'Off';
type ViewType = 'Card' | 'Detail';

export interface PendoIdentifier {
  component: string;
  view?: ViewType;
  socialTracking?: SocialTrackingType;
  trackingType?: TrackingType;
  frequency?: keyof typeof UserChallengeFrequency;
  privacy?: Privacy;
  toggleState?: ToggleState;
  filterType?: DiscoverActivityTypes;
  category?: keyof typeof DiscoverCategories;
}

const pendoIdentifierBuilder = (args: PendoIdentifier): string => {
  return Object.values(args)
    .map((item) => (item ? item.charAt(0).toUpperCase() + item.slice(1) : ''))
    .join('')
    .replace('-', '');
};
export default pendoIdentifierBuilder;
