import { useStore } from '../stores/Root.store';
import { ApiContext as Api } from './ApiContext';

import ApiWrapper from '../utilities/apiWrapper';

export interface ApiProviderOptions {
  children?: React.ReactNode;
}

const ApiProvider = (opts: ApiProviderOptions): JSX.Element => {
  const { children } = opts;

  const { AppAuthStore } = useStore();

  return <Api.Provider value={{ api: new ApiWrapper(AppAuthStore.getAccessToken.bind(AppAuthStore)) }}>{children}</Api.Provider>;
};

export default ApiProvider;
