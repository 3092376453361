import { observer } from 'mobx-react-lite';
import { Button, Flex } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { useErrorBoundary } from 'react-error-boundary';
import './LoginButton.css';
import { useStore } from 'src/stores/Root.store';
import Logger from 'src/logger/Logger';

const colors = {
  normalBackground: '#0058A0',
  hoverBackground: '#004782',
  activeBackground: '#004782',
};

const styles = {
  button: {
    color: '#FFFFFF',
    maxWidth: '250px',
    width: '80%',
    background: colors.normalBackground,

    '&:hover': {
      background: colors.hoverBackground,
    },

    '&:active': {
      background: colors.activeBackground,
    },
  },

  questionIcon: {
    color: colors.normalBackground,
    background: '#FFF',
    padding: '0px',
    '&:hover': {
      color: colors.hoverBackground,
    },
  },

  logInButtonArea: {
    width: '100%',
    animationName: 'fadeIn',
    animationDuration: '1s',
    paddingTop: '40px',
  },
};

const LoginButton: React.FC = observer(() => {
  const { AppAuthStore } = useStore();
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const buttonClickHandler = () => {
    Logger.trackButtonClick('Login');

    try {
      AppAuthStore.setLimeadeAuthIsLoading(true);
      if (AppAuthStore.isValid) {
        AppAuthStore.limeadeAuthMethods.loginWithRedirect();
      } else {
        AppAuthStore.limeadeAuthMethods.loginWithPopup();
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <Flex hAlign="center" vAlign="center" gap="gap.smaller" styles={styles.logInButtonArea}>
      <Button
        id="signInButton"
        primary
        size="medium"
        styles={styles.button}
        content={t('buttons.login.content')}
        onClick={buttonClickHandler}
      />
    </Flex>
  );
});

export default LoginButton;
