const splashSreenStyles = {
  backgroundStyle: {
    height: '100%',
    width: '100%',
    background: 'linear-gradient(to bottom right, #cccccc, #f9f9f9)',
  },
  logoStyle: {
    width: '80%',
    maxWidth: '400px',
    opacity: 0.6,
  },
  logoTextStyle: {
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '27px',
    fontStyle: 'italic',
    color: 'white',
  },
};

export default splashSreenStyles;
