export const TYPE_FLAGS = {
  None: 0,
  IncentiveEvent: 1,
  PersonalGoal: 2,
  ChallengeGoal: 4,
  ResourceFileDrivenItem: 8,
  Recommendation: 16,
  Dimension: 64,
};

export enum LimeadeAuthProviders {
  AUTH0 = 'AUTH0',
}

export enum RequestType {
  GET,
  POST,
  PUT,
}

export const LimeadeAuthStates = {
  Anonymous: { isAuthenticated: false, isLoading: false },
  Authenticated: { isAuthenticated: true, isLoading: false },
  Error: { isAuthenticated: false, isLoading: false },
};

export const TeamsUserStates = {
  NotInTeams: { error: undefined, inTeams: false, isLoading: false, teamsUser: undefined },
};

export const CALENDAR_WIDTH = 315;

export enum Components {
  ACTIVITY_CARD = 'ACTIVITY_CARD',
  ACTIVITY_DETAILS = 'ACTIVITY_DETAILS',
  CREATE_TEAM = 'CREATE_TEAM',
  INVITATION_POP_UP = 'INVITATION_POP_UP',
  JOIN_TEAM = 'JOIN_TEAM',
  JOIN_TEAM_BOARD = 'JOIN_TEAM_BOARD',
  JOINED_ACTIVITY_VIEW = 'JOINED_ACTIVITY_VIEW',
  MY_TEAM = 'MY_TEAM',
}

export enum PopUpEventTypes {
  INVITATION = 'INVITATION',
  INVITE_TEAMMATES = 'INVITE_TEAMMATES',
}

export enum Views {
  CREATE_TEAM = 'CREATE_TEAM',
  JOIN_TEAM = 'JOIN_TEAM',
}

export enum Tabs {
  HOME = 'home',
  DISCOVER = 'discover',
  ACHIEVEMENTS = 'achievements',
}

export enum TabIds {
  HOME = 0,
  DISCOVER = 1,
  ACHIEVEMENTS = 2,
  ACTIVITY_DETAILS = 3,
}

export enum TrackingType {
  YES_NO_DAILY = 'yesnodaily', //E.g. 3 times per week until activity time is over
  ADD_ALL_NUMBERS = 'addallnumbers', //E.g. 100 hours at all or per week/day/etc (numeric input)
  ONE_TIME_EVENT = 'onetimeevent',
}

export enum Privacy {
  SELF = 'self',
  PUBLIC = 'public',
}

export enum NotificationType {
  JOIN_TEAM = 'JoinTeam',
  CHEERS = 'Cheers',
  INVITATION = 'Invitation',
  TEAM_POSITION = 'TeamPosition',
  COMPLETE_ACTIVITY = 'CompleteActivity',
  SECOND_WELCOME = 'SecondWelcomeNotificationSent',
  JOINED_ACTIVITY_REMINDER_LINK_CLICKED = 'JoinedActivityReminderLinkClicked',
  JOINED_ACTIVITY_REMINDER_BUTTON_CLICKED = 'JoinedActivityReminderButtonClicked',
}

export const MyChoiceConstant = 'TopPicksForYou';
export const MyChoiceTrait = 'MyChoice';

export enum DiscoverActivityTypes {
  ONE_TIME = 'ONE_TIME',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
  INDIVIDUAL = 'INDIVIDUAL',
  TEAM = 'TEAM',
  SURVEY_AND_QUIZZES = 'SURVEY_AND_QUIZZES',
  DEVICE_ENABLED = 'DEVICE_ENABLED',
}

export const DiscoverActivityTypesTranslationKey = {
  [DiscoverActivityTypes.ONE_TIME]: 'ActivityType_OneTime',
  [DiscoverActivityTypes.WEEKLY]: 'ActivityType_Weekly',
  [DiscoverActivityTypes.DAILY]: 'Daily',
  [DiscoverActivityTypes.INDIVIDUAL]: 'ActivityType_Individual',
  [DiscoverActivityTypes.TEAM]: 'ActivityType_Team',
  [DiscoverActivityTypes.SURVEY_AND_QUIZZES]: 'ActivityType_SurveyAndQuizzes',
  [DiscoverActivityTypes.DEVICE_ENABLED]: 'ActivityType_DeviceEnabled',
};

export enum UserChallengeFrequency {
  Daily = 0,
  Weekly = 1, // Weekly is the default Time Period when a target is set
  Monthly = 2,
  Quarterly = 3,
  Yearly = 4,
  None = 5, //used for one time challenges
  SpecificDate = 1001, // in which case Goal.Deadline has a specific DateTime set
}

export type LifeAreaKey = 'EMOTIONAL' | 'PHYSICAL' | 'CAPACITY_FOR_CHANGE' | 'WORK' | 'HEALTH_RISK' | 'ACTUALIZATION';

export enum LifeArea {
  EMOTIONAL = 'Emotional',
  PHYSICAL = 'Physical',
  CAPACITY_FOR_CHANGE = 'Capacity for Change',
  WORK = 'Work',
  HEALTH_RISK = 'Health Risk: Limeade',
  ACTUALIZATION = 'Actualization',
}

// Bitwise status flags for an activity/challenge
export enum UserChallengeStatus {
  None = 0,
  NeverJoined = 1,
  InProgress = 2,
  Completed = 4,
  Expired = 8,
  Warning = 16,
  Removed = 32,
  Invited = 64,
}

export enum EventType {
  NUMBERIC = 'Numeric',
  BOOLEAN = 'Boolean',
}

export enum ColorTheme {
  DEFAULT = 'default',
  DARK = 'dark',
  CONTRAST = 'contrast',
}

// Bitwise tracking mode flags
export enum TrackingMode {
  None = 0,
  SelfReport = 1,
  Device = 2,
  DataFeed = 4,
}

export enum ActivityViewMode {
  CARD = 'Card',
  DETAILS = 'Detail',
}
export enum SocialTrackingMode {
  TEAM = 'Team',
  SELF = 'Self',
}

export enum CalendarButtonDirection {
  NEXT = 'next',
  PREVIOUS = 'previous',
}

export enum ThemeModes {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum MgtClassName {
  LIGHT = 'mgt-light',
  DARK = 'mgt-dark',
}

export enum ChartTypes {
  RADIAL_BAR = 'radialBar',
  BAR = 'bar',
}

export enum ArrowKey {
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
}

export enum CommonKey {
  ENTER = 'Enter',
  SPACE = ' ',
}

export enum ActivityUnit {
  MINUTE = 'minutes',
  HOUR = 'hours',
  MONMENT = 'monments',
  FLIGHT = 'flights',
  LITER = 'liters',
  STEP = 'steps',
  CALORIE = 'calories',
  MILE = 'miles',
  PUSHUP = 'push-ups',
  TIME = 'times',
  TIMES_WEEKLY = 'YesNoDailyActivity_TimesWeekly',
}

export enum PageSize {
  DESKTOP_FIRST_LOAD = 8,
  DESKTOP_LOAD_MORE = 12,
  MOBILE_FIRST_LOAD = 3,
  MOBILE_LOAD_MORE = 9,
  MY_POINTS_FIRST_LOAD = 5,
}

export type OverallValue = number | undefined;

// we removed the localization limitation for Microsoft Testing Site: '81ad849e-37ac-4e20-b939-4385da8c34c8'
// We still keep this logic here if we need this feature back in future.
export const TENANT_IDS_WITH_LANGUAGE_LIMITED: string[] = [];

export const HOME_URL = '/home';
export const DISCOVER_URL = '/discover';

export const LOGIN_IN_PROGRESS = 'LOGIN_PROGRESS';
export const LOGIN_CALLBACK_IN_PROGRESS = 'LOGIN_CALLBACK_IN_PROGRESS';
export const POPUP_INTRODUCTION = 'POPUP_INTRODUCTION';
export const LIMEADE_EXT_UPN = 'https://limeade.com/ext_upn';
export const LIMEADE_EXT_OID = 'https://limeade.com/ext_oid';
export const LIMEADE_ACCOUNT_ID = 'https://limeade.com/limeade_account_id';
export const LIMEADE_TENANT_ID = 'https://limeade.com/one_tenant_id';
export const STORED_USER_MAPPING = 'STORED_USER_MAPPING';
export const STORED_USER_LOCALE = 'STORED_USER_LOCALE';
export const LIMEADE_ROLE = 'https://limeade.com/role';

export enum AppConfigs {
  TURNOFFWBA = 'turnOffWba',
}

export enum Auth0ValidationStatus {
  ACCESS_DENIED = 'AccessDenied',
  USER_NOT_FOUND = 'UserNotFound',
  CANCELLED_BY_USER = 'CancelledByUser',
  TOKEN_IS_NOT_GOTTEN = 'TokenIsNotGotten',
  GET_SILENT_TOKEN_FAILED = 'GetSilentTokenFailed',
  UNKNOWN_FAILED = 'UnknownFailed',
  SUCCESS = 'SUCCESS',
}

export enum RoutePath {
  Home = '/home',
  Discover = '/discover',
  Achievements = '/achievements',
  JoinTeam = '/activity/:id/jointeam',
  ActivityDetailsView = '/activity/:id',
  MyPoints = '/myPoints',
  Auth0Login = '/auth0login',
  Auth0LoginCallback = '/auth0logincallback',
  Auth0Logout = '/auth0logout',
  Auth0LogoutCallback = '/auth0logoutcallback',
  LoginView = '/loginview',
  Auth = '/auth',
}

export const BreadcrumbsNameResourceKey = {
  [RoutePath.Home]: 'Home',
  [RoutePath.Discover]: 'Discover',
  [RoutePath.Achievements]: 'Achievements',
  [RoutePath.MyPoints]: 'MyPoints',
};

export enum Roles {
  ProgramAdmin = 'ProgramAdmin',
}

export enum authenticationStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export const TEAMS_URL = 'https://teams.microsoft.com';
