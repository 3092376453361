import { HashRouter as Router, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { lazy, Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { initializeIcons } from '@fluentui/font-icons-mdl2';

import UIProviderWrapper from './components/shared/UIProviderWrapper/UIProviderWrapper';
import LoadingAnnouncementProvider from './components/shared/LoadingAnnouncementProvider/LoadingAnnouncementProvider';
import ErrorLogBoundary from './utilities/errorLogBoundary';
import { ProtectedRoute } from './auth/ProtectedRoute';
import { StoreProvider } from './stores/Root.store';
import ApiProvider from './api/ApiProvider';
import ApplicationDialog from './components/dialogs/ApplicationDialog';
import AuthProvider from './auth/AuthProvider';
import Logger from './logger/Logger';
import AuthorizedLoggerProvider from './logger/AuthorizedLoggerProvider';
import LoginView from './components/views/LoginView';
import LimeadeLoader from './components/shared/Loader/Loader';
import TeamsSSO from './auth/TeamsSSO';
import { reactPlugin } from './AppInsights';
import Auth0Login from './auth/Auth0Login';
import Auth0Logout from './auth/Auth0Logout';
import Auth0LogoutCallback from './auth/Auth0LogoutCallback';

import Auth0LoginCallback from './auth/Auth0LoginCallback';
import RootPane from './components/shared/RootPane';
import './mgt.css';
import i18n from './i18n';
import CmsContextProviderLoader from './components/shared/CMSProvider/CmsContextProviderLoader';
import Zendesk from './components/shared/Zendesk';
import TeamsGraphAuth from './auth/TeamsGraphAuth';
import TeamsJsWrapper from './auth/TeamsJsWrapper';
import { RoutePath } from './utilities/constants';
import { NotificationReceiverWrapper } from './components/shared/NotificationReceiver/NotificationReceiver';

initializeIcons();

const Home = lazy(() => import(/* webpackChunkName: "home" */ './components/tabs/Home'));
const Discover = lazy(
  () => import(/* webpackChunkName: "discover", webpackPrefetch: true */ './components/tabs/Discover')
);
const Achievements = lazy(
  () => import(/* webpackChunkName: "achievements", webpackPrefetch: true */ './components/tabs/Achievements')
);
const JoinTeamView = lazy(() => import(/* webpackChunkName: "joinTeamView" */ './components/views/JoinTeamView'));
const ActivityDetailsView = lazy(
  () =>
    import(
      /* webpackChunkName: "activityDetailsView", webpackPrefetch: true */ './components/views/ActivityDetailsView'
    )
);
const MyPoints = lazy(() => import(/* webpackChunkName: "myPoints" */ './components/views/MyPointsView/MyPoints'));
/**
 * The main app which handles the initialization and routing.
 */
const App: React.FC<any> = observer((props) => {
  TeamsGraphAuth.initialize();
  TeamsJsWrapper.app.initialize();
  Logger.initialize();

  return (
    <StoreProvider>
      <I18nextProvider i18n={i18n}>
        <CmsContextProviderLoader>
          <UIProviderWrapper>
            <ErrorLogBoundary>
              <LoadingAnnouncementProvider>
                <RootPane>
                  <AuthProvider>
                    <AuthorizedLoggerProvider>
                      <Router>
                        <ApiProvider>
                          <ApplicationDialog />
                          <NotificationReceiverWrapper />
                          <Suspense fallback={<LimeadeLoader />}>
                            <Switch>
                              <ProtectedRoute path={RoutePath.Home} component={Home} requiresAuthentication={true} />
                              <ProtectedRoute
                                path={RoutePath.Discover}
                                component={Discover}
                                requiresAuthentication={true}
                              />
                              <ProtectedRoute
                                path={RoutePath.Achievements}
                                component={Achievements}
                                requiresAuthentication={true}
                              />
                              <ProtectedRoute
                                path={RoutePath.MyPoints}
                                component={MyPoints}
                                requiresAuthentication={true}
                              />
                              <ProtectedRoute
                                path={RoutePath.JoinTeam}
                                component={JoinTeamView}
                                requiresAuthentication={true}
                              />

                              <ProtectedRoute
                                path={RoutePath.ActivityDetailsView}
                                component={ActivityDetailsView}
                                requiresAuthentication={true}
                              />
                              <ProtectedRoute
                                path={RoutePath.Auth0Login}
                                component={Auth0Login}
                                requiresAuthentication={false}
                              />
                              <ProtectedRoute
                                path={RoutePath.Auth0Logout}
                                component={Auth0Logout}
                                requiresAuthentication={false}
                              />
                              <ProtectedRoute
                                path={RoutePath.Auth0LogoutCallback}
                                component={Auth0LogoutCallback}
                                requiresAuthentication={false}
                              />
                              <ProtectedRoute
                                path={RoutePath.Auth0LoginCallback}
                                component={Auth0LoginCallback}
                                requiresAuthentication={false}
                              />
                              <ProtectedRoute
                                path={RoutePath.LoginView}
                                component={LoginView}
                                requiresAuthentication={false}
                              />
                              <ProtectedRoute
                                path={RoutePath.Auth}
                                component={TeamsSSO}
                                requiresAuthentication={true}
                              />
                            </Switch>
                          </Suspense>
                        </ApiProvider>
                      </Router>
                    </AuthorizedLoggerProvider>
                  </AuthProvider>
                </RootPane>
              </LoadingAnnouncementProvider>
              <Zendesk />
            </ErrorLogBoundary>
          </UIProviderWrapper>
        </CmsContextProviderLoader>
      </I18nextProvider>
    </StoreProvider>
  );
});

export default withAITracking(reactPlugin, App);
