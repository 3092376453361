import * as axios from 'axios';
import { TeamsFx } from '@microsoft/teamsfx';

import { getIsPrivateServerValue, guidToInt, htmlDecode } from './utilities';
import { isActivityTracked } from './activityUtilities';
import { TYPE_FLAGS, RequestType, DiscoverActivityTypes } from '../utilities/constants';
import { Activity, TeamsActivity } from '../models/Activity';
import { TrackingAction } from '../models/TrackingAction';
import { Team } from '../models/Team';
import { TeamsLimeadeUserMapping } from '../models/TeamsLimeadeUserMapping';
import Config from '../Config';
import ActivityDetails from '../models/ActivityDetails';
import { Startup } from '../models/Startup';
import { NotificationContext } from '../../../api/notifications/models/NotificationContext';
import i18n from '../i18n';
import { TeamParticipant } from 'src/models/TeamParticipant';
import { TeamsUserLocale } from '../models/TeamsUserLocale';
import { InformedConsent } from 'src/models/InformedConsent';
import DiscoverActivitiesResponse from 'src/models/DiscoverActivitiesResponse';
import ExceptionTypes from './exceptionTypes';
import { DeviceSync } from 'src/models/DeviceSync';

type AccessTokenGetter = () => Promise<string | null>;

export interface ApiWrapperInterface {
  createTeam(activityId: string, team: Partial<Team>): Promise<any>;
  getActivity(activityId: string): Promise<Activity>;
  getActivityDetailedInfo(activityId: string): Promise<ActivityDetails>;
  getCurrentTeamInfo(activityId: string, teamId: string): Promise<Team>;
  fetchDiscoverActivities(
    category: string,
    pagingToken?: string,
    search?: string,
    types?: DiscoverActivityTypes[],
    excludeMyChoice?: boolean,
    count?: number
  ): Promise<DiscoverActivitiesResponse>;
  getLifeAreaScores(): Promise<any>;
  getMyActivities(): Promise<Activity[]>;
  getMyTeamInfo(activityId: string): Promise<Team | null>;
  getMyTeamLeaderboard(activityId: string): Promise<any>;
  getProfile(): Promise<any>;
  getTeamParticipants(activityId: string, teamId: string): Promise<TeamParticipant[]>;
  getTeams(activityId: string): Promise<Team[]>;
  getTeamsLeaderboard(activityId: string): Promise<any>;
  getUserLevels(): Promise<any>;
  getPointsHistory(levelOrder?: number, pagingToken?: string, count?: number): Promise<any>;
  joinActivity(activityId: string, isPrivate: boolean): Promise<any>;
  joinTeam(activityId: string, teamId: string): Promise<any>;
  leaveActivity(activityId: string): Promise<any>;
  moveToHistory(activityId: string): Promise<any>;
  sendNotification(notificationContext: NotificationContext): Promise<any>;
  startup(): Promise<Startup>;
  trackActivity(trackingAction: TrackingAction): Promise<any>;
  updateActivityPrivacy(activityId: number, privacy: boolean): Promise<any>;
  storeTeamsLimeadeUser(userMapping: TeamsLimeadeUserMapping): Promise<void>;
  getUserMappingByLimeadeAccountIds(limeadeAccountIds: number[]): Promise<TeamsLimeadeUserMapping[]>;
  getRichMediaInsightUserPlayDetails(activityId: string, mediaId?: string): Promise<any>;
  checkConsent(): Promise<InformedConsent>;
  getConsent(versionId: string): Promise<any>;
  signConsent(versionId: string): Promise<void>;
  getAllDeviceSyncInfo(): Promise<DeviceSync[]>;
}

export default class ApiWrapper {
  private _accessTokenGetter: AccessTokenGetter;

  public get accessTokenGetter(): AccessTokenGetter {
    return this._accessTokenGetter;
  }

  public set accessTokenGetter(value: AccessTokenGetter) {
    this._accessTokenGetter = value;
  }

  constructor(accessTokenGetter: () => Promise<string | null>) {
    this._accessTokenGetter = accessTokenGetter;
  }
  private async callLimeadeApi(
    apiFunctionPath: string,
    requestType: RequestType,
    params?: any,
    payload?: any,
    headerOptions?: any,
    authenticationRequired = true
  ): Promise<any> {
    return this.callApi(
      Config.limeadeApiUrl,
      apiFunctionPath,
      requestType,
      params,
      payload,
      headerOptions,
      authenticationRequired
    );
  }

  private async callLimeadeApiAnonymously(
    apiFunctionPath: string,
    requestType: RequestType,
    params?: any,
    payload?: any
  ): Promise<any> {
    return this.callLimeadeApi(apiFunctionPath, requestType, params, payload, null, false);
  }

  private async callOneApi(
    apiFunctionPath: string,
    requestType: RequestType,
    params?: any,
    payload?: any,
    headerOptions?: any
  ): Promise<any> {
    return this.callApi(Config.oneApiUrl, apiFunctionPath, requestType, params, payload, headerOptions);
  }

  private async callApi(
    apiBaseUrl: string,
    apiFunctionPath: string,
    requestType: RequestType,
    params?: any,
    payload?: any,
    headerOptions?: any,
    authenticationRequired = true
  ): Promise<any> {
    try {
      const token = await this.accessTokenGetter();

      if (authenticationRequired && token == null) {
        throw new Error(ExceptionTypes.ACCESS_TOKEN_NULL);
      }

      const config = {
        params: params,
        headers: { ...headerOptions },
      };

      if (authenticationRequired && token) {
        config.headers = {
          ...config.headers,
          authorization: 'Bearer ' + token,
        };
      }

      let response;
      switch (requestType) {
        case RequestType.GET:
          response = await axios.default.get(apiBaseUrl + apiFunctionPath, config);
          break;
        case RequestType.POST:
          response = await axios.default.post(apiBaseUrl + apiFunctionPath, payload, config);
          break;
        case RequestType.PUT:
          response = await axios.default.put(apiBaseUrl + apiFunctionPath, payload, config);
          break;
      }

      return response.data;
    } catch (err: any) {
      throw err;
    }
  }

  private async callAzureFunction(functionName: String, requestType: RequestType, params?: any, payload?: any) {
    try {
      const config = {
        params: params,
        headers: {},
      };

      const teamsFx = new TeamsFx();
      const credential = teamsFx.getCredential();
      const accessToken = await credential.getToken('');

      config.headers = {
        Authorization: 'Bearer ' + accessToken?.token || '',
      };

      const functionEndpoint = teamsFx.getConfig('apiEndpoint');

      const response =
        requestType === RequestType.GET
          ? await axios.default.get(functionEndpoint + '/api/' + functionName, config)
          : await axios.default.post(functionEndpoint + '/api/' + functionName, payload, config);
      return response.data;
    } catch (err: unknown) {
      if (axios.default.isAxiosError(err)) {
        let funcErrorMsg = '';

        if (err?.response?.status === 404) {
          funcErrorMsg = `There may be a problem with the deployment of Azure Function App, please deploy Azure Function (Run command palette "TeamsFx - Deploy Package") first before running this App`;
        } else if (err.message === 'Network Error') {
          funcErrorMsg =
            'Cannot call Azure Function due to network error, please check your network connection status and ';
          if (err.config?.url && err.config.url.indexOf('localhost') >= 0) {
            funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
          } else {
            funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "TeamsFx - Provision Resource" and "TeamsFx - Deploy Package") first before running this App`;
          }
        } else {
          if (err.response?.data?.error) {
            funcErrorMsg = err.response.data.error;
          }
        }

        err.message = `${err.message}. ${funcErrorMsg}`;
        throw err;
      }

      throw err;
    }
  }

  public async getProfile(): Promise<any> {
    try {
      return await this.callLimeadeApi(`api/profile`, RequestType.GET);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_CANNOT_GET_PROFILE, { cause: error });
    }
  }

  public async getAllDeviceSyncInfo(): Promise<DeviceSync[]> {
    try {
      const response = await this.callLimeadeApi(`api/v2/devicesync/all`, RequestType.GET, null);
      return response ?? [];
    } catch (error: any) {
      throw new Error(ExceptionTypes.CANNOT_GET_ALL_DEVICES_SYNC_INFO, { cause: error });
    }
  }

  public async getUserLevels(): Promise<any> {
    try {
      return await this.callLimeadeApi(`api/rewards/levels`, RequestType.GET, null);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_USER_LEVELS, { cause: error });
    }
  }

  public async getPointsHistory(
    levelOrder: number | null = null,
    pagingToken: string | null = '',
    count: number | null = null
  ): Promise<any> {
    try {
      const queryString = `?levelOrder=${levelOrder}&pagingToken=${pagingToken}&count=${count}`;
      return await this.callLimeadeApi(`api/rewards/pointshistorylevel${queryString}`, RequestType.GET, null);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_POINTS_HISTORY, { cause: error });
    }
  }

  public async getMyActivities(): Promise<Activity[]> {
    const apiPath = `activityserver/Activities/my`;
    try {
      return (await this.callLimeadeApi(apiPath, RequestType.GET, { count: 100, locale: i18n.language })).items;
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_MY_ACTIVITIES, { cause: error });
    }
  }

  public async getActivity(activityId: string): Promise<Activity> {
    try {
      return await this.callLimeadeApi(
        `activitycatalog/ActivityContent/${i18n.language}/${activityId}`,
        RequestType.GET
      );
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_ACTIVITY_BY_ID, { cause: error });
    }
  }

  public async getActivityDetailedInfo(activityId: string): Promise<ActivityDetails> {
    const apiPath = `api/activity/${guidToInt(activityId)}/Get`;
    try {
      const activityDetails = await this.callLimeadeApi(apiPath, RequestType.GET, {
        types: 4,
        status: 0,
        attributes: 0,
        contents: 2623,
      });

      return activityDetails?.Data[0];
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_ACTIVITY_DETAIL, { cause: error });
    }
  }

  public async getTeamsActivity(activityId: string): Promise<TeamsActivity> {
    const [activity, activityDetails] = await Promise.all([
      this.getActivity(activityId),
      this.getActivityDetailedInfo(activityId),
    ]);

    return {
      ...activity,
      activityDetails: activityDetails,
      isTracked: isActivityTracked(activityDetails, activity.trackingType, new Date()),
    };
  }

  public async fetchDiscoverActivities(
    category: string,
    pagingToken = '',
    search = '',
    types: DiscoverActivityTypes[] = [],
    excludeMyChoice = false,
    count = 20
  ): Promise<DiscoverActivitiesResponse> {
    let typeParams = '';
    if (types.length) {
      typeParams = types.map((type) => `types=${type}`).join('&');
      typeParams = `&${typeParams}`;
    }
    try {
      return await this.callLimeadeApi(
        `activityserver/activities/discovery/${category}?search=${search}${typeParams}&excludeMyChoice=${excludeMyChoice}&count=${count}&pagingToken=${pagingToken}&locale=${i18n.language}`,
        RequestType.GET
      );
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_FETCH_PAGINATION_DISCOVER_ACTIVITY, { cause: error });
    }
  }

  public async getMyTeamInfo(activityId: string): Promise<Team | null> {
    try {
      const myTeamInfo = await this.callLimeadeApi(`api/activity/${guidToInt(activityId)}/teams/my`, RequestType.GET);
      if (!myTeamInfo) {
        return null;
      }
      return { ...myTeamInfo, teamName: htmlDecode(myTeamInfo?.TeamName) };
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_TEAM_INFOR, { cause: error });
    }
  }

  public async getCurrentTeamInfo(activityId: string, teamId: string): Promise<Team> {
    try {
      const myTeamInfo = await this.callLimeadeApi(
        `api/activity/${guidToInt(activityId)}/teams/${teamId}`,
        RequestType.GET
      );
      return { ...myTeamInfo, teamName: htmlDecode(myTeamInfo.TeamName) };
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_CURRENT_TEAM_INFO, { cause: error });
    }
  }

  public async getTeams(activityId: string): Promise<Team[]> {
    try {
      const teams = await this.callLimeadeApi(`api/activity/${guidToInt(activityId)}/teams`, RequestType.GET);
      return teams.Data.map((team: any) => ({ ...team, TeamName: htmlDecode(team.TeamName) }));
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_TEAMS, { cause: error });
    }
  }

  public async getMyTeamLeaderboard(activityId: string): Promise<any> {
    const apiPath = `api/leaderboard`;
    try {
      return await this.callLimeadeApi(apiPath, RequestType.GET, {
        activityid: guidToInt(activityId),
        type: 'TeamView',
      });
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_MY_TEAM_LEADERBOARD, { cause: error });
    }
  }

  public async getTeamsLeaderboard(activityId: string): Promise<any> {
    // type={Top20,TagBased,TeamView,Team}
    const apiPath = `api/leaderboard`;
    try {
      const leaderboard = await this.callLimeadeApi(apiPath, RequestType.GET, {
        activityid: guidToInt(activityId),
        type: 'Team',
      });

      const myDisplayName = leaderboard.Data?.Leaderboard?.MyEntry?.DisplayName;
      const LeaderboardEntries = leaderboard.Data?.Leaderboard?.LeaderboardEntries;

      if (myDisplayName) {
        leaderboard.Data.Leaderboard.MyEntry.DisplayName = htmlDecode(myDisplayName);
      }

      if (!!LeaderboardEntries) {
        leaderboard.Data.Leaderboard.LeaderboardEntries = leaderboard.Data.Leaderboard.LeaderboardEntries.map(
          (team: any) => ({
            ...team,
            DisplayName: htmlDecode(team.DisplayName),
          })
        );
      }

      return leaderboard;
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_TEAM_LEADERBOARD, { cause: error });
    }
  }

  public async getTeamParticipants(activityId: string, teamId: string): Promise<TeamParticipant[]> {
    try {
      return await this.callLimeadeApi(
        `api/activity/${guidToInt(activityId)}/teams/${teamId}/participants`,
        RequestType.GET
      );
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_TEAM_PARTICIPANTS, { cause: error });
    }
  }

  public async createTeam(activityId: string, team: Partial<Team>): Promise<any> {
    try {
      return await this.callLimeadeApi(`api/activity/${guidToInt(activityId)}/Team`, RequestType.POST, null, team);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_CREATE_TEAM, { cause: error });
    }
  }

  //This method allows you to join your own team so its does not have teamId param, only your own team is allowed
  public async joinActivity(activityId: string, isPrivate: boolean = false): Promise<any> {
    const queryString = `api/activity/${guidToInt(activityId)}/join`;
    try {
      const data = {
        Id: guidToInt(activityId),
        Type: TYPE_FLAGS.ChallengeGoal,
        ActionType: 'JoinChallenge',
        PrivacyFlag: getIsPrivateServerValue(isPrivate),
        Details: {
          StartValue: '0',
        },
      };

      return await this.callLimeadeApi(queryString, RequestType.POST, null, data);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_JOIN_ACTIVITY, { cause: error });
    }
  }

  public async leaveActivity(activityId: string): Promise<any> {
    const queryString = `api/activity/${guidToInt(activityId)}/leave`;
    const data = {
      ActionType: 'leavechallenge',
      Details: {},
      Id: guidToInt(activityId),
      Type: 4,
    };
    try {
      return await this.callLimeadeApi(queryString, RequestType.POST, null, data);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_LEAVE_ACTIVITY, { cause: error });
    }
  }

  public async moveToHistory(activityId: string): Promise<any> {
    const queryString = `api/activity/${guidToInt(activityId)}/hide`;

    const data = {
      ActionType: 'hidechallenge',
      Details: {},
      Id: guidToInt(activityId),
      Type: 4,
    };
    try {
      return await this.callLimeadeApi(queryString, RequestType.POST, null, data);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_MOVE_TO_HISTORY, { cause: error });
    }
  }

  public async joinTeam(activityId: string, teamId: string): Promise<any> {
    try {
      return await this.callLimeadeApi(`api/activity/${guidToInt(activityId)}/JoinTeam/${teamId}`, RequestType.PUT);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_JOIN_TEAM, { cause: error });
    }
  }

  public async updateActivityPrivacy(activityId: number, privacy: boolean): Promise<any> {
    const apiPath = `api/activity/${activityId}/settings`;
    try {
      return await this.callLimeadeApi(apiPath, RequestType.PUT, { privacyflag: +privacy });
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_UPDATE_ACTIVITY_PRIVACY, { cause: error });
    }
  }

  public async trackActivity(trackingAction: TrackingAction): Promise<any> {
    try {
      return await this.callLimeadeApi(
        `api/activity/${trackingAction.Id}/event`,
        RequestType.POST,
        null,
        trackingAction
      );
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_TRACK_ACTIVITY, { cause: error });
    }
  }

  public async getLifeAreaScores(): Promise<any> {
    try {
      return await this.callLimeadeApi(`api/profile/lifeAreaScores/${i18n.language}`, RequestType.GET);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_LIFE_AREA_SCORES, { cause: error });
    }
  }

  public async startup(): Promise<Startup> {
    try {
      return await this.callOneApi('app/startup', RequestType.GET, null);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_STARTUPDATA, { cause: error });
    }
  }

  public async getAuthConfigByAADTenantId(tenantId: string): Promise<any> {
    const apiPath = `api/v2/tenant/authConfig/byADDTenantId`;
    try {
      return await this.callLimeadeApiAnonymously(apiPath, RequestType.GET, {
        app: 'limeadeOneMSTeams',
        aadTenantId: tenantId,
      });
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new Error(`${ExceptionTypes.TENANT_AUTH_RECORD_MISSING}: ${tenantId}`, { cause: error });
      } else {
        throw new Error(ExceptionTypes.CANNOT_GET_TENANT_CONFIG_RECORD, { cause: error });
      }
    }
  }

  public async sendNotification(notificationContext: NotificationContext): Promise<any> {
    try {
      return await this.callAzureFunction(`sendNotification`, RequestType.POST, null, notificationContext);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_SEND_NOTIFICATION, { cause: error });
    }
  }

  public async storeTeamsLimeadeUser(userMapping: TeamsLimeadeUserMapping): Promise<any> {
    try {
      return await this.callAzureFunction('storeTeamsLimeadeUser', RequestType.POST, null, userMapping);
    } catch (error: any) {
      if (error.response.status !== 409) {
        throw new Error(ExceptionTypes.CANNOT_STORE_TEAMS_LIMEADE_USER, { cause: error });
      }
    }
  }

  public async getUserMappingByLimeadeAccountIds(limeadeAccountIds: number[]): Promise<TeamsLimeadeUserMapping[]> {
    try {
      return await this.callAzureFunction('getUserMappingByLimeadeAccountIds', RequestType.POST, null, {
        limeadeAccountIds,
      });
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_ACTIVITY_USER_MAPPING_BY_LIMEADE_ACCOUNTIDS, { cause: error });
    }
  }

  public async storeUserLocale(teamsUserLocale: TeamsUserLocale): Promise<void> {
    try {
      return await this.callAzureFunction('storeUserLocale', RequestType.POST, null, teamsUserLocale);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_STORE_USER_LOCALE, { cause: error });
    }
  }

  public async fetchAppConfigs(): Promise<any> {
    try {
      return await this.callAzureFunction('fetchAppConfigs', RequestType.GET);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_FETCH_APP_CONFIG, { cause: error });
    }
  }

  public async getRichMediaInsightUserPlayDetails(activityId: string, mediaId?: string): Promise<any> {
    try {
      return await this.callLimeadeApi(
        `api/v2/richmedia/Insights/Media/${mediaId}/activity/${activityId}/UserPlayDetail`,
        RequestType.GET
      );
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_RICHMEDIA_INSIGHT_USER_PLAYDETAILS, { cause: error });
    }
  }

  public async checkConsent(): Promise<InformedConsent> {
    try {
      return await this.callLimeadeApi(`api/v2/consent/consent/check`, RequestType.GET);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_CHECK_CONSENT, { cause: error });
    }
  }

  public async getConsent(versionId: string): Promise<any> {
    try {
      return await this.callLimeadeApi(`api/v2/consent/consent/${versionId}`, RequestType.GET);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_CONSENT, { cause: error });
    }
  }

  public async signConsent(versionId: string): Promise<void> {
    try {
      return await this.callLimeadeApi(
        `api/v2/consent/consent/sign`,
        RequestType.POST,
        null,
        JSON.stringify(versionId),
        {
          'Content-Type': 'application/json',
        }
      );
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_SIGN_CONSENT, { cause: error });
    }
  }

  public async fetchFeatureFlags(features: string[]): Promise<any> {
    let params = '';
    try {
      if (features?.length) {
        params = features.map((feature) => `feature=${feature}`).join('&');
      }
      return this.callLimeadeApi(`api/user/features/?${params}`, RequestType.GET);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_FETCH_FEATURE_FLAGS, { cause: error });
    }
  }

  public async getCriteriaAlert(levelOrder: number | null = null) {
    try {
      return this.callLimeadeApi(`api/rewards/criteriaAlert?levelOrder=${levelOrder}`, RequestType.GET);
    } catch (error) {
      throw new Error(ExceptionTypes.CANNOT_GET_CRITERIA_ALERT, { cause: error });
    }
  }
}
