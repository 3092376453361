import React from 'react';
import { useTeamsFx } from 'src/lib/useTeamsFx';
import { Provider as UIProvider, mergeThemes } from '@fluentui/react-northstar';
import { FluentProvider } from '@fluentui/react-components';
import { isAuthorization } from 'src/utilities/utilities';

export interface UIProviderWrapperProps {
  children?: React.ReactNode;
}
const globalStyles = {
  lineHeight: 1.4286,
};

const UIProviderWrapper: React.FC<UIProviderWrapperProps> = ({ children }): JSX.Element | null => {
  const { theme, themeInfo, loading, context, fluentUiTheme } = useTeamsFx();

  if (!isAuthorization() && (loading || !context)) {
    return null;
  }

  return (
    <FluentProvider theme={fluentUiTheme.theme}>
      <UIProvider theme={mergeThemes(theme, themeInfo.getCustomTheme())}>
        <div style={globalStyles}>{children}</div>
      </UIProvider>
    </FluentProvider>
  );
};

export default UIProviderWrapper;
