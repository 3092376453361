const styles = {
  backgroundColor: 'lightgray',
  padding: '6px',
  borderRadius: '4px',
  border: '1px solid darkgray',
  fontSize: '14px',
  lineHeight: '14px',
  fontWeight: 600,
};

export default styles;
