import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useData } from '../lib/useData';
import LimeadeLoader from '../components/shared/Loader/Loader';
import {
  LOGIN_CALLBACK_IN_PROGRESS,
  LIMEADE_EXT_UPN,
  LIMEADE_EXT_OID,
  Auth0ValidationStatus,
} from '../utilities/constants';
import { authentication } from './TeamsJsWrapper';

const Auth0LoginCallback = () => {
  const { getAccessTokenSilently, user, isLoading } = useAuth0();

  const validateUser = (user: any) => {
    if (!user) {
      return Auth0ValidationStatus.USER_NOT_FOUND;
    }

    if (!user[LIMEADE_EXT_UPN] || !user[LIMEADE_EXT_OID]) {
      return `The user principle name and object id could not be found. Invalid data: ${JSON.stringify(user)}.`;
    }
    return Auth0ValidationStatus.SUCCESS;
  };

  const validateAuth0UrlCallBack = (searchUrl: string) => {
    const params = new URLSearchParams(searchUrl);
    const error = params.get('error');
    const error_description = params.get('error_description');
    if (error === 'access_denied' && error_description === 'No matching limeade account') {
      return Auth0ValidationStatus.ACCESS_DENIED;
    } else if (error) {
      const statusFailed = `${error} ${error_description}`;
      return statusFailed;
    }
    return Auth0ValidationStatus.SUCCESS;
  };

  const { loading: isLoadingCallback, reload: loginCallback } = useData(
    async () => {
      const loginCallback = async () => {
        if (!!window.localStorage.getItem(LOGIN_CALLBACK_IN_PROGRESS)) {
          // Let's wait until previous auth flow is completed.
          setTimeout(() => {
            window.localStorage.removeItem(LOGIN_CALLBACK_IN_PROGRESS);

            window.alert('Authentication interrupted. Need to sign in again.');
            window.close();
          }, 10000);

          return;
        }

        window.localStorage.setItem(LOGIN_CALLBACK_IN_PROGRESS, 'true');

        // First validation: check the status returned from url callback. Make sure we call this validation before validateUser function
        try {
          const status = validateAuth0UrlCallBack(window.location.search);
          if (status !== Auth0ValidationStatus.SUCCESS) {
            authentication.notifyFailure(status);
            return;
          }
        } catch (error: any) {
          authentication.notifyFailure(error.message);
        }

        // Second validation: If URL call is success. Make sure we can get the user based on Auth0 SDK
        const status = validateUser(user);
        if (status !== Auth0ValidationStatus.SUCCESS) {
          authentication.notifyFailure(status);
          return;
        }

        // Third validation: Test that token can be gotten after login and use it as an indication of auth success/failure.
        try {
          const token = await getAccessTokenSilently();
          if (!token) {
            authentication.notifyFailure(Auth0ValidationStatus.TOKEN_IS_NOT_GOTTEN);
            return;
          }
        } catch (error: any) {
          authentication.notifyFailure(`${Auth0ValidationStatus.GET_SILENT_TOKEN_FAILED}. ${error.message}`);
        }

        authentication.notifySuccess(user);
      };

      await loginCallback();
    },
    { auto: false }
  );

  useEffect(() => {
    if (!isLoading) {
      loginCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (isLoading || isLoadingCallback) {
    return <LimeadeLoader />;
  }
  return null;
};

export default Auth0LoginCallback;
