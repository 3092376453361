import { teamsLightTheme, teamsDarkTheme, teamsHighContrastTheme } from '@fluentui/react-components';
import type { Theme } from '@fluentui/react-components';

import { ColorTheme } from './utilities/constants';

export class FluentUiTheme {
  theme: Theme;

  constructor(themeString: string) {
    this.theme = this.getFluentUiTheme(themeString);
  }

  private getFluentUiTheme(themeString: string): Theme {
    if (themeString === ColorTheme.CONTRAST) {
      return teamsHighContrastTheme;
    } else if (themeString === ColorTheme.DARK) {
      return teamsDarkTheme;
    }

    return teamsLightTheme;
  }
}
