import { ThemePrepared } from '@fluentui/react-northstar';
import { ColorTheme } from './utilities/constants';

interface IBaseColors {
  tabBackground: string;
  calendarBackground: string;
  cardBackground: string;
  inputBackground: string;
  dropdownBackground: string;
  dropdownBackgroundHover: string;
  emphasizedBackground: string;
  activityLink: string;
  activityLinkFocus: string;
  personCardExpandPath: string;
  myChoiceBackground: string;
  myChoiceColor: string;
  userProgressBackground: string;
  userProgressBarBackground: string;
  levelCircle: string;
  shimmerBackgroundColor: string;
  shimmerGradientColor: string;
  requiredSign: string;
  feedbackBlockTitleColor: string;
  feedbackValidationLabel: string;
  foregroundColor3: string;
  buttonBackground1: string;
  buttonHoverBackground1: string;
  buttonActiveBackground1: string;
  breadcrumbBackground: string;
  breadcrumbBorder: string;
  backButtonIconColor: string;
  headerTableBackground: string;
  progressBarPointsColor: string;
  pointsHistoryTextColor: string;
  pointsHistoryDetailsBorderColor: string;
  foregroundBrand: string;
  foregroundDefault: string;
  backgroundBrand: string;
  aquaticBackground: string;
  collapsedTextColor: string;
}

export class ThemeInfoEx {
  private theme: ThemePrepared;
  themeName: ColorTheme;
  colorScheme: IBaseColors;
  constructor(theme: ThemePrepared, themeString: string) {
    this.theme = theme;
    this.themeName = this.getThemeName(themeString);
    this.colorScheme = this.getCustomColors();
  }

  private getThemeName(themeString: string): ColorTheme {
    if (themeString === ColorTheme.CONTRAST) {
      return ColorTheme.CONTRAST;
    } else if (themeString === ColorTheme.DARK) {
      return ColorTheme.DARK;
    }

    return ColorTheme.DEFAULT;
  }

  private getCustomColors(): any {
    const baseColors = {
      tabBackground: this.theme.siteVariables.colorScheme.default.background3,
      calendarBackground: this.theme.siteVariables.colorScheme.default.background1,
      cardBackground: this.theme.siteVariables.colorScheme.default.background2,
      inputBackground: this.theme.siteVariables.colorScheme.default.background,
      dropdownBackground: this.theme.siteVariables.colorScheme.default.background1,
      dropdownBackgroundHover: this.theme.siteVariables.colorScheme.default.background1,
      emphasizedBackground: this.theme.siteVariables.colorScheme.brand.border,
      activityLink: this.theme.siteVariables.colorScheme.brand.background6,
      activityLinkFocus: this.theme.siteVariables.colorScheme.brand.foregroundPressed,
      personCardExpandPath: this.theme.siteVariables.colorScheme.default.foreground,
      myChoiceBackground: this.theme.siteVariables.colorScheme.default.foreground3,
      myChoiceColor: this.theme.siteVariables.colorScheme.brand.background,
      userProgressBackground: this.theme.siteVariables.colorScheme.default.background,
      userProgressBarBackground: this.theme.siteVariables.colorScheme.brand.background,
      levelCircle: this.theme.siteVariables.colorScheme.brand.background,
      shimmerBackgroundColor: '#d6d8d8',
      shimmerGradientColor: '#fff 50%',
      requiredSign: '#FF7D7D',
      feedbackBlockTitleColor: 'rgb(192,192,192)',
      feedbackValidationLabel: 'rgb(255, 125, 125)',
      foregroundColor3: this.theme.siteVariables.colorScheme.default.foreground3,
      buttonBackground1: '#0058A0',
      buttonHoverBackground1: '#004782',
      buttonActiveBackground1: '#004782',
      breadcrumbBackground: this.theme.siteVariables.colorScheme.default.background,
      breadcrumbBorder: this.theme.siteVariables.colorScheme.default.border,
      backButtonIconColor: this.theme.siteVariables.colorScheme.brand.background,
      headerTableBackground: '#1F1F1F',
      progressBarPointsColor: '#5B5FC7',
      pointsHistoryTextColor: '#3D3D3D',
      pointsHistoryDetailsBorderColor: '#E1DFDD',
      foregroundBrand: '#4F52B2',
      foregroundDefault: '#FFF',
      backgroundBrand: '#E8EBFA',
      aquaticBackground: '#202020',
      collapsedTextColor: '#5B5FC7',
    };

    if (this.themeName === ColorTheme.DEFAULT) {
      return {
        ...baseColors,
        cardBackground: this.theme.siteVariables.colorScheme.default.background,
        inputBackground: this.theme.siteVariables.colorScheme.default.background1,
        requiredSign: '#B62626',
        feedbackBlockTitleColor: 'rgb(96,96,96)',
        feedbackValidationLabel: 'rgb(182, 38, 38)',
        headerTableBackground: '#F5F5F5',
      };
    }

    if (this.themeName === ColorTheme.CONTRAST) {
      return {
        ...baseColors,
        emphasizedBackground: this.theme.siteVariables.colorScheme.default.background,
        activityLink: this.theme.siteVariables.colorScheme.brand.foreground2,
        activityLinkFocus: this.theme.siteVariables.colorScheme.brand.foregroundActive2,
        myChoiceColor: this.theme.siteVariables.colorScheme.brand.foreground4,
        levelCircle: this.theme.siteVariables.colorScheme.brand.foreground4,
        userProgressBarBackground: this.theme.siteVariables.colorScheme.brand.backgroundFocus1,
        shimmerGradientColor: '#3b3a39 50%',
        headerTableBackground: '#000000',
        progressBarPointsColor: '#ffffff',
        pointsHistoryTextColor: '#ffffff',
        collapsedTextColor: '#75E9FC',
      };
    }

    if (this.themeName === ColorTheme.DARK) {
      return {
        ...baseColors,
        shimmerGradientColor: '#3b3a39 50%',
        progressBarPointsColor: '#ffffff',
        pointsHistoryTextColor: '#ffffff',
        collapsedTextColor: '#7F85F5',
      };
    }

    return baseColors;
  }

  getCustomTheme(): any {
    return {
      componentVariables: {
        Provider: ({ colorScheme }: { colorScheme: any }) => ({
          backgroundColor: this.colorScheme.tabBackground,
        }),
        Card: () => ({
          backgroundColor: this.colorScheme.cardBackground,
          backgroundColorHover: this.colorScheme.cardBackground,
          backgroundColorPressed: this.colorScheme.cardBackground,
        }),
        Input: () => ({
          backgroundColor: this.colorScheme.inputBackground,
        }),
        Dropdown: () => ({
          backgroundColor: this.colorScheme.dropdownBackground,
          backgroundColorHover: this.colorScheme.dropdownBackgroundHover,
          backgroundColorPressed: this.colorScheme.dropdownBackgroundHover,
        }),
      },
      componentStyles: {
        Provider: {
          root: ({ variables }: { variables: any }) => ({
            backgroundColor: variables.backgroundColor,
          }),
        },
      },
    };
  }
}
