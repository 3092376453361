import { useTranslation } from 'react-i18next';
import { LocalizationHelper } from '@microsoft/mgt-react';

import { useStore } from 'src/stores/Root.store';

export const MicrosoftGraphLocalization: React.FC = () => {
  const { t } = useTranslation();
  const { LocalizationStore } = useStore();

  LocalizationHelper.strings = {
    _components: {
      'people-picker': {
        inputPlaceholderText: LocalizationStore.getString('Fow_MicrosoftGraph_PeoplePicker_InputPlaceholderText'),
        noResultsFound: LocalizationStore.getString('Fow_NoResultsFound'),
        loadingMessage: t('customStrings.loading'),
      },
      'teams-channel-picker': {
        inputPlaceholderText: LocalizationStore.getString('Fow_MicrosoftGraph_TeamsChannelPicker_InputPlaceholderText'),
        noResultsFound: LocalizationStore.getString('Fow_NoResultsFound'),
        loadingMessage: t('customStrings.loading'),
      },
      'person-card': {
        sendEmailLinkSubtitle: LocalizationStore.getString('Fow_MicrosoftGraph_PersonCard_SendEmailLinkSubtitle'),
        startChatLinkSubtitle: LocalizationStore.getString('Fow_MicrosoftGraph_PersonCard_StartChatLinkSubtitle'),
        showMoreSectionButton: LocalizationStore.getString('Fow_MicrosoftGraph_PersonCard_ShowMoreSectionButton'),
      },
      'person-card-contact': {
        contactSectionTitle: LocalizationStore.getString('Fow_MicrosoftGraph_PersonCardContact_ContactSectionTitle'),
        emailTitle: LocalizationStore.getString('ShareViaEmail'),
        chatTitle: LocalizationStore.getString('Fow_MicrosoftGraph_PersonCardContact_ChatTitle'),
        businessPhoneTitle: LocalizationStore.getString('Fow_MicrosoftGraph_PersonCardContact_BusinessPhoneTitle'),
        cellPhoneTitle: LocalizationStore.getString('Fow_MicrosoftGraph_PersonCardContact_CellPhoneTitle'),
        departmentTitle: LocalizationStore.getString('Fow_MicrosoftGraph_PersonCardContact_DepartmentTitle'),
        titleTitle: LocalizationStore.getString('RichMedia_VideoDetails_TitleLabel'),
        officeLocationTitle: LocalizationStore.getString('Fow_MicrosoftGraph_PersonCardContact_OfficeLocationTitle'),
      },
      'person-card-organization': {
        reportsToSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardOrganization_ReportsToSectionTitle'
        ),
        directReportsSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardOrganization_DirectReportsSectionTitle'
        ),
        organizationSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardOrganization_OrganizationSectionTitle'
        ),
        youWorkWithSubSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardOrganization_YouWorkWithSubSectionTitle'
        ),
        userWorksWithSubSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardOrganization_UserWorksWithSubSectionTitle'
        ),
      },
      'person-card-messages': {
        emailsSectionTitle: LocalizationStore.getString('Fow_MicrosoftGraph_PersonCardMessages_EmailsSectionTitle'),
      },
      'person-card-files': {
        filesSectionTitle: LocalizationStore.getString('Fow_MicrosoftGraph_PersonCardFiles_FilesSectionTitle'),
        sharedTextSubtitle: LocalizationStore.getString('Fow_MicrosoftGraph_PersonCardFiles_SharedTextSubtitle'),
      },
      'person-card-profile': {
        SkillsAndExperienceSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardProfile_SkillsAndExperienceSectionTitle'
        ),
        AboutCompactSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardProfile_AboutCompactSectionTitle'
        ),
        SkillsSubSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardProfile_SkillsSubSectionTitle'
        ),
        LanguagesSubSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardProfile_LanguagesSubSectionTitle'
        ),
        WorkExperienceSubSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardProfile_WorkExperienceSubSectionTitle'
        ),
        EducationSubSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardProfile_EducationSubSectionTitle'
        ),
        professionalInterestsSubSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardProfile_ProfessionalInterestsSubSectionTitle'
        ),
        personalInterestsSubSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardProfile_PersonalInterestsSubSectionTitle'
        ),
        birthdaySubSectionTitle: LocalizationStore.getString(
          'Fow_MicrosoftGraph_PersonCardProfile_BirthdaySubSectionTitle'
        ),
        currentYearSubtitle: LocalizationStore.getString('Fow_MicrosoftGraph_PersonCardProfile_CurrentYearSubtitle'),
      },
    },
  };

  return null;
};
