import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const options: ToastOptions<{}> = {
  position: 'top-right',
  autoClose: 3000,
  pauseOnFocusLoss: true,
};

const showToast = {
  notify: async (message: string): Promise<any> => {
    return toast.success(message, options);
  },
  info: async (message: string): Promise<any> => {
    return toast.info(message, options);
  },
  error: async (message: string): Promise<any> => {
    return toast.error(message, options);
  },
  alwaysShow: async (message: string | JSX.Element, onClose: <T = {}>(props: T) => void, toastId?: string, bodyStyle?: React.CSSProperties): Promise<any> => {
    const alwaysShowOptions: ToastOptions<{}> = {
      position: 'top-right',
      autoClose: false,
      toastId: toastId,
      bodyStyle: bodyStyle,
      onClose: onClose,
    };

    return toast.info(message, alwaysShowOptions);
  },
  isActive: (toastId: string): boolean => {
    return toast.isActive(toastId);
  },
};

export default showToast;
