import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import LimeadeLoader from '../components/shared/Loader/Loader';
import { authentication } from './TeamsJsWrapper';
import { authenticationStatus } from 'src/utilities/constants';

const Auth0LogoutCallback = () => {
  const { getIdTokenClaims } = useAuth0();
  const logoutCallback = async () => {
    const claims = await getIdTokenClaims();
    if (!claims) {
      authentication.notifySuccess();
    } else {
      authentication.notifyFailure(authenticationStatus.FAILED);
    }
  };

  useEffect(() => {
    logoutCallback();
  });

  return <LimeadeLoader />;
};

export default Auth0LogoutCallback;
