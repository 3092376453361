import { useEffect } from 'react';
import { User as Auth0User, useAuth0 } from '@auth0/auth0-react';

import { LimeadeAuthState } from './LimeadeAuthState';
import Logger, { Events } from '../logger/Logger';
import {
  LimeadeAuthStates,
  LOGIN_CALLBACK_IN_PROGRESS,
  LIMEADE_EXT_UPN,
  LIMEADE_ACCOUNT_ID,
  LIMEADE_TENANT_ID,
  LIMEADE_EXT_OID,
  Auth0ValidationStatus,
  authenticationStatus,
} from '../utilities/constants';
import { LoginResult } from 'src/models/LoginResult';
import { useStore } from '../stores/Root.store';
import { useData } from '../lib/useData';
import { isAuthorization, checkAuth0User, Auth0UserResult } from 'src/utilities/utilities';
import ExceptionTypes from 'src/utilities/exceptionTypes';
import { authentication } from './TeamsJsWrapper';

export interface Auth0ProviderWrapperOptions {
  children?: React.ReactNode;
}

const Auth0ProviderWrapper = (opts: Auth0ProviderWrapperOptions): JSX.Element => {
  const { children } = opts;
  const { getAccessTokenSilently, logout } = useAuth0();
  const { AppAuthStore } = useStore();

  const getAccessToken = async () => {
    if (AppAuthStore.isAuthenticated) {
      return getAccessTokenSilently();
    }
    return null;
  };

  const clearLocalStorageLoginStates = () => {
    window.localStorage.removeItem(LOGIN_CALLBACK_IN_PROGRESS);
  };

  AppAuthStore.setAccessTokenGetter(getAccessToken.bind(this));

  const logoutWithPopup = async () => {
    const result: string = await authentication.authenticate({
      url: `${window.location.origin}/#/auth0logout`,
      width: 600,
      height: 768,
    });
    try {
      const logoutResult = JSON.parse(result);
      if (logoutResult.status === authenticationStatus.SUCCESS) {
        logout({ localOnly: true });
        AppAuthStore.resetAuthotrizedTeamsUserId();
        AppAuthStore.resetLimeadeAuthState();
        Logger.trackEvent(Events.AuthFlowFinished, { message: `Auth0 logout successfully.`, result: result });
      } else {
        Logger.trackEvent(Events.AuthFlowError, { message: `Auth0 logout failed.`, result: result });
      }
    } catch (error: any) {
      notifyAuthenticationFailure(ExceptionTypes.AUTH_ERROR, error.message);
    }
  };

  const loginWithPopup = async () => {
    try {
      const result = await authentication.authenticate({
        url: `${window.location.origin}/#/auth0login`,
        width: 600,
        height: 768,
      });

      const logoutResult: LoginResult = JSON.parse(result);
      if (logoutResult.status === authenticationStatus.SUCCESS) {
        // notifySuccess/notifyFailure returns object instead of string on mobile in Auth End page
        // this is a workaround for the issue from https://github.com/OfficeDev/microsoft-teams-library-js/issues/942

        const userObj = logoutResult.detail;

        const auth0UserResult: Auth0UserResult = checkAuth0User(userObj);

        if (auth0UserResult.exceptionType) {
          notifyAuthenticationFailure(auth0UserResult.exceptionType, auth0UserResult.exceptionDetail);
          return;
        }
        const user = auth0UserResult.user;
        const aadTenantId = AppAuthStore.aadTenantId;
        clearLocalStorageLoginStates();

        if (user && aadTenantId) {
          const authState: LimeadeAuthState<Auth0User> = { limeadeUser: user, ...LimeadeAuthStates.Authenticated };
          const userPrincipalName = user[LIMEADE_EXT_UPN];
          userPrincipalName && AppAuthStore.setAuthotrizedTeamsUserId(userPrincipalName);
          AppAuthStore.setLimeadeAuthState(authState);
          const userMapping = {
            upn: userPrincipalName,
            oid: user[LIMEADE_EXT_OID],
            aadId: aadTenantId,
            limeadeEmail: user.email, // TODO: should rename limeadeEmail to aadUserEmail
            limeadeUserId: +user[LIMEADE_ACCOUNT_ID],
            limeadeTenantId: user[LIMEADE_TENANT_ID],
          };

          AppAuthStore.storeTeamsLimeadeUser(userMapping);

          Logger.trackEvent(Events.AuthFlowFinished, { message: `Authenticated successfully.` });
        } else {
          notifyAuthenticationFailure(ExceptionTypes.AUTH_ERROR);
        }
      }
    } catch (error: any) {
      clearLocalStorageLoginStates();

      switch (error.message) {
        case Auth0ValidationStatus.CANCELLED_BY_USER:
          AppAuthStore.resetLimeadeAuthState();
          break;
        case Auth0ValidationStatus.ACCESS_DENIED:
          notifyAuthenticationFailure(ExceptionTypes.AUTH_ACCESS_DENIED);
          break;
        case Auth0ValidationStatus.USER_NOT_FOUND:
          notifyAuthenticationFailure(ExceptionTypes.AUTH_USER_NOT_FOUND);
          break;
        default:
          notifyAuthenticationFailure(ExceptionTypes.AUTH_ERROR, error.message);
          break;
      }
    }
  };

  const loginWithRedirect = async () => {
    // Trigger token getting.
    await getAccessTokenSilently();
  };

  const logoutWithRedirect = () => {
    const returnTo = `${window.location.origin}/#/auth0logoutcallback`;

    logout({ returnTo, client_id: AppAuthStore.authConfig.clientId });
    return Promise.resolve();
  };

  const removeUser = () => {
    return logoutWithRedirect();
  };

  const notifyAuthenticationFailure = (message: string, detail?: string) => {
    const errorState = { error: new Error(message, { cause: detail }), ...LimeadeAuthStates.Error };
    Logger.trackEvent(Events.AuthFlowError, { message: `${message}. Reason: ${detail}` });
    AppAuthStore.setLimeadeAuthState(errorState);
  };

  const Auth0Listener = (opts: Auth0ProviderWrapperOptions): JSX.Element => {
    const { children } = opts;
    const { isAuthenticated, isLoading: auth0IsLoading, user, getAccessTokenSilently } = useAuth0();
    const { AppAuthStore } = useStore();

    // Valid refresh_token from localStorage is not used if auth0.is.authenticated cookie has expired (user seems logged out)
    // https://github.com/auth0/auth0-spa-js/issues/704
    // We fix the issue by calling getAccessTokenSilently()
    const silentLogin = async () => {
      try {
        if (auth0IsLoading === false && !isAuthenticated) {
          return await getAccessTokenSilently();
        }
        return null;
      } catch (error) {
        return null;
      }
    };

    const getLimeadeAuthState = async (): Promise<LimeadeAuthState<Auth0User>> => {
      if (auth0IsLoading || !user || !isAuthenticated) {
        // Do nothing, user will be redirected to login view by router.
        return LimeadeAuthStates.Anonymous;
      }

      const upn = user[LIMEADE_EXT_UPN];
      const oid = user[LIMEADE_EXT_OID];

      if (!upn || !oid) {
        notifyAuthenticationFailure(ExceptionTypes.AUTH_ERROR, 'Silent login cannot get the UPN and OID');
        return LimeadeAuthStates.Anonymous;
      }

      return { limeadeUser: user, ...LimeadeAuthStates.Authenticated };
    };

    const { data: limeadeAuthState, loading: authStateLoading } = useData(getLimeadeAuthState);
    const { loading: silentLoginLoading } = useData(silentLogin);

    useEffect(() => {
      if (!auth0IsLoading && !authStateLoading && !silentLoginLoading) {
        AppAuthStore.setLimeadeAuthState(limeadeAuthState);
        const { limeadeUser } = limeadeAuthState;
        const inTeams = AppAuthStore.teamsUserState.inTeams;
        if (limeadeUser && inTeams) {
          const userPrincipalName = limeadeUser[LIMEADE_EXT_UPN];
          const oid = limeadeUser[LIMEADE_EXT_OID];
          const aadTenantId = AppAuthStore.aadTenantId;

          if (userPrincipalName && oid && aadTenantId) {
            AppAuthStore.setAuthotrizedTeamsUserId(userPrincipalName);

            if (!AppAuthStore.isSwitchUser && !isAuthorization()) {
              const userMapping = {
                upn: userPrincipalName,
                oid: oid,
                aadId: aadTenantId,
                limeadeEmail: limeadeUser.email!, // TODO: should rename limeadeEmail to aadUserEmail
                limeadeUserId: +limeadeUser[LIMEADE_ACCOUNT_ID],
                limeadeTenantId: limeadeUser[LIMEADE_TENANT_ID],
              };

              AppAuthStore.storeTeamsLimeadeUser(userMapping);
            }
          }
        }
      } else {
        AppAuthStore.setLimeadeAuthIsLoading(true);
      }
    }, [authStateLoading, auth0IsLoading, silentLoginLoading, AppAuthStore, limeadeAuthState]);

    AppAuthStore.setLimeadeAuthMethods({
      getAccessToken,
      loginWithPopup,
      logoutWithPopup,
      loginWithRedirect,
      logoutWithRedirect,
      removeUser,
    });

    return <>{children}</>;
  };
  return <Auth0Listener>{children}</Auth0Listener>;
};

export default Auth0ProviderWrapper;
