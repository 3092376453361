import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useAuth0 } from '@auth0/auth0-react';
import { useErrorBoundary } from 'react-error-boundary';

import Logger from './Logger';
import { useStore } from '../stores/Root.store';
import { isAuthorization } from 'src/utilities/utilities';

export interface AuthorizedLoggerProviderOptions {
  children?: React.ReactNode;
}

const AuthorizedLoggerProvider: React.FC<AuthorizedLoggerProviderOptions> = observer(({ children }): JSX.Element => {
  const { isLoading: auth0IsLoading } = useAuth0();
  const { AppAuthStore, UserProfileStore } = useStore();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const setupLogger = async () => {
      try {
        if (!isAuthorization() && AppAuthStore.isAuthenticated) {
          const startupData = await UserProfileStore.getStartupData();
          if (startupData) {
            Logger.authorize(startupData);
          }
        } else {
          Logger.unauthorize();
        }
      } catch (error) {
        showBoundary(error);
      } finally {
        Logger.resolveStartupDataLoadedPromise();
      }
    };

    if (!auth0IsLoading && !AppAuthStore.isLoading) {
      setupLogger();
    }
  }, [AppAuthStore.isAuthenticated, AppAuthStore.isLoading, UserProfileStore, auth0IsLoading, showBoundary]);

  return <>{children}</>;
});

export default AuthorizedLoggerProvider;
