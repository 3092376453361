const chartBarItemStyles = {
  barLine: {
    borderRadius: '3px',
    width: '100%',
    transition: 'all 1s ease-in',
  },
  barValue: {
    fontSize: '14px',
    fontWeight: 'bold',
    paddingLeft: '4px',
    width: '28px',
  },
  label: {
    fontSize: '14px',
    lineHeight: '14px',
    paddingBottom: '7px',
  },
};

export default chartBarItemStyles;
