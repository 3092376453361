import { CheckboxProps } from '@fluentui/react-northstar';
import type { OptionOnSelectData } from '@fluentui/react-combobox';
import { observable, action, makeObservable } from 'mobx';

import Logger, { Events } from '../logger/Logger';
import { TeamsActivity } from '../models/Activity';
import { DiscoverActivityTypes, MyChoiceTrait, UserChallengeFrequency } from '../utilities/constants';
import { RootStore } from './Root.store';
import { DiscoverCategories } from 'src/utilities/categories';

export default class ActivityFilterStore {
  rootStore: RootStore;
  isDiscoverTab = false;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable dropdownFilters: ((item: TeamsActivity) => boolean)[] = [];
  @observable searchFilters: ((item: TeamsActivity) => boolean)[] = [];
  @observable input: string = '';
  @observable dropdownValue: string[] = [];
  @observable filterApplied: string[] = [];
  @observable includeMyChoice: boolean = true;
  @observable selectedTypes: DiscoverActivityTypes[] = [];

  LISTENING_TOOLS: string = 'listeningtools';
  CATEGORY_FILTERS: { [categoryName: string]: (item: TeamsActivity) => boolean } = {
    [DiscoverActivityTypes.ONE_TIME]: (item: TeamsActivity): boolean =>
      item.trackingStrategy.frequency === UserChallengeFrequency[UserChallengeFrequency.None],
    [DiscoverActivityTypes.WEEKLY]: (item: TeamsActivity): boolean =>
      item.trackingStrategy.frequency === UserChallengeFrequency[UserChallengeFrequency.Weekly],
    [DiscoverActivityTypes.DAILY]: (item: TeamsActivity): boolean =>
      item.trackingStrategy.frequency === UserChallengeFrequency[UserChallengeFrequency.Daily],
    [DiscoverActivityTypes.INDIVIDUAL]: (item: TeamsActivity): boolean => !item.socialTracking,
    [DiscoverActivityTypes.TEAM]: (item: TeamsActivity): boolean => !!item.socialTracking,
    [DiscoverActivityTypes.SURVEY_AND_QUIZZES]: (item: TeamsActivity): boolean =>
      item.partnerApplication?.type === this.LISTENING_TOOLS,
    [DiscoverActivityTypes.DEVICE_ENABLED]: (item: TeamsActivity): boolean => item.isDeviceEnabled,
  };

  myChoiceFilter = (item: TeamsActivity): boolean => !item.traits?.includes(MyChoiceTrait);

  setIsDiscoverTab = (value: boolean) => {
    this.isDiscoverTab = value;
  };

  setSelectTypes = (types: DiscoverActivityTypes[]) => {
    this.selectedTypes = types;
  };

  @action
  handleSearch = (input: string) => {
    this.input = input;
  };

  handleSearchSubmitted = (event?) => {
    event?.stopPropagation();
    const value = this.input.toLowerCase();
    this.searchFilters = [(item: TeamsActivity) => item.title.toLowerCase().includes(value)];
    this.applyFiltersForHome();
    this.applyFilterForDiscover();
  };

  @action
  handleCategoriesChange = (event: any, data: OptionOnSelectData) => {
    const pickedCategories = data.selectedOptions;

    if (Array.isArray(data.selectedOptions)) {
      this.dropdownValue = pickedCategories.map((item: any) => item);
      this.dropdownFilters = pickedCategories.map((filterType: any) => this.CATEGORY_FILTERS[filterType]);
      this.selectedTypes = pickedCategories.map((item: any) => item);
      this.applyFiltersForHome();
      this.applyFilterForDiscover();
    }
  };

  @action
  handleMyChoiceChange = (
    event: unknown,
    value: (Omit<CheckboxProps, 'checked'> & { checked: boolean }) | undefined
  ) => {
    this.includeMyChoice = !!value?.checked;
    this.applyFiltersForHome();
    this.applyFilterForDiscover();
  };

  @action
  applyFilterForDiscover = () => {
    if (this.isDiscoverTab && !this.rootStore.CardStore.isFirstLoading) {
      this.rootStore.CardStore.setIsFirstLoading(true);
      this.rootStore.ActivityStore.resetPagination();
      this.rootStore.ActivityStore.firstLoadDiscoverActivities();

      const logFilterApplied = [...this.dropdownValue];
      if (!!this.input) {
        logFilterApplied.push(this.input);
      }

      if (!this.includeMyChoice) {
        logFilterApplied.push('Exclude My Choice');
      }

      this.filterApplied = logFilterApplied;
      this.logFilterForDiscover();
    }
  };

  logFilterForDiscover = async () => {
    if (this.filterApplied.length) {
      const idSet = new Set();
      Object.values(DiscoverCategories).forEach((category) => {
        const categorySection = this.rootStore.ActivityStore.getDiscoverActivitiesByCategory(category);
        categorySection.activities?.forEach((activity) => idSet.add(activity.id)); // store unique values
      });

      const activityAmount = Array.from(idSet).length;
      const result = {
        filterApplied: this.filterApplied,
        activityAmount,
      };
      Logger.trackEvent(Events.FilterApplied, result);
    }
  };

  @action
  applyFiltersForHome = (): void => {
    if (!this.isDiscoverTab) {
      if (!this.searchFilters.length || !this.dropdownFilters.length) {
        this.rootStore.CardStore.setAppliedFilters([]);
      }

      this.filterApplied = [...this.dropdownValue, this.input].filter(Boolean);

      const filters = [...this.dropdownFilters, ...this.searchFilters];

      if (!this.includeMyChoice) {
        filters.push(this.myChoiceFilter);
      }

      this.rootStore.CardStore.setAppliedFilters(filters);
      const result = this.rootStore.CardStore.homeCards;

      if (this.filterApplied.length) {
        Logger.trackEvent(Events.FilterApplied, this.logFilterApplied(result));
      }
    }
  };

  logFilterApplied = (filteredActivities: TeamsActivity[]) => {
    return {
      filterApplied: this.filterApplied,
      activityAmount: filteredActivities.length,
    };
  };

  logFilterAppliedWhenDetailsOrJoin = (activityId: string, clickTarget: 'Details' | 'Join') => {
    Logger.trackEvent(clickTarget === 'Details' ? Events.FilterAppliedThenDetails : Events.FilterAppliedThenJoin, {
      activityId,
      filterApplied: this.filterApplied,
      clickTarget,
    });
  };
}
