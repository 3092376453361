import { TeamsLimeadeUserMapping } from 'src/models/TeamsLimeadeUserMapping';
import ApiWrapper from 'src/utilities/apiWrapper';
import { RootStore } from './Root.store';

export default class UserMappingStore {
  rootStore: RootStore;
  mappingsMap = new Map<number, TeamsLimeadeUserMapping>();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  fetchUserMappingsByIds = async (limeadeAccountIds: number[]) => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const noMappingIds = limeadeAccountIds.filter((id) => !this.mappingsMap.has(id));
    if (noMappingIds.length > 0) {
      const mappings = await apiWrapper.getUserMappingByLimeadeAccountIds(noMappingIds);
      mappings.forEach((item) => {
        this.mappingsMap.set(item.limeadeUserId, item);
      });
    }

    return limeadeAccountIds.map((id) => this.mappingsMap.get(id));
  };

  getUserMappingById = (limeadeAccountId: number) => {
    return this.mappingsMap.get(limeadeAccountId);
  };
}
