import { isMobile } from 'react-device-detect';

const appStyles = {
  pageContainer: {
    padding: isMobile ? '10px 30px' : '30px',
    width: '100%',
    maxWidth: '1684px',
    minWidth: '320px',
  },
  wrapper: {
    height: '100%',
    overflow: 'auto',
  },
  dialog: {
    width: '90%',
    maxWidth: '600px',
    maxHeight: '97%',
  },
};

export default appStyles;
