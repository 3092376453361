import { createContext, useContext } from 'react';
import { useLocalObservable } from 'mobx-react-lite';

import AchievementsStore from './Achievements.store';
import ActivityFilterStore from './ActivityFilter.store';
import ActivityStore from './Activity.store';
import AppAuthStore from './AppAuth.store';
import ApplicationStore from './Application.store';
import CardStore from './Card.store';
import UserProfileStore from './UserProfile.store';
import UserMappingStore from './UserMapping.store';
import LocalizationStore from './Localization.store';

export class RootStore {
  AchievementsStore = new AchievementsStore(this);
  ActivityFilterStore = new ActivityFilterStore(this);
  ActivityStore = new ActivityStore(this);
  AppAuthStore = new AppAuthStore(this);
  ApplicationStore = new ApplicationStore(this);
  CardStore = new CardStore(this);
  UserProfileStore = new UserProfileStore(this);
  UserMappingStore = new UserMappingStore(this);
  LocalizationStore = new LocalizationStore(this);
}

const RootStoreContext = createContext(new RootStore());

export const useStore = () => useContext(RootStoreContext);

export const StoreProvider = ({ children }: any) => {
  const rootStore = useLocalObservable(useStore);

  return <RootStoreContext.Provider value={rootStore}>{children}</RootStoreContext.Provider>;
};
