import React from 'react';
import { Flex, FlexProps } from '@fluentui/react-northstar';
import styles from './BlockWrapper.style';
import { useTeamsFx } from 'src/lib/useTeamsFx';

type ChildrenContent = React.ReactElement | React.ReactElement[];

interface BlockWrapperProps extends FlexProps {
  children: ChildrenContent;
}

const BlockWrapper: React.FC<BlockWrapperProps> = ({ children, ...props }) => {
  const { theme } = useTeamsFx();
  const backgroundColor = theme.siteVariables.colorScheme.default.background;

  return (
    <Flex {...props} styles={{ ...styles.root, ...props.styles, backgroundColor }}>
      {children}
    </Flex>
  );
};

export default BlockWrapper;
