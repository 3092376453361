import { isMobile } from 'react-device-detect';

const programQuietTimeStyles = {
  wrapper: { height: '100%' },
  pqtWrapper: {
    width: '60%',
    minWidth: '350px',
  },
  pqtImage: {
    marginTop: isMobile ? '56px' : '124px',
  },
  pqtTitle: {
    whiteSpace: 'pre-line',
    fontWeight: 700,
    fontSize: '18px',
    marginTop: '16px',
  },
  pqtMessage: {
    marginTop: '16px',
    whiteSpace: 'pre-line',
    fontWeight: 400,
    fontSize: '14px',
  },
};

export default programQuietTimeStyles;
