import { TeamsMsal2Provider } from '@microsoft/mgt-teams-msal2-provider';
import jwtDecode from 'jwt-decode';

export default class TeamsProvider extends TeamsMsal2Provider {
  accessToken = '';
  decodedToken;

  checkTokenExpired = () => {
    if (this.accessToken) {
      const expirationTime = this.decodedToken.exp * 1000; // Expiration time in milliseconds
      const currentTime = Date.now();
      const timeRemaining = expirationTime - currentTime;

      return timeRemaining <= 0;
    }
    return true;
  };

  getAccessToken = async (options?: any): Promise<string> => {
    const isTokenExpiredSoon = this.checkTokenExpired();
    if (isTokenExpiredSoon) {
      this.accessToken = await super.getAccessToken(options);
      this.decodedToken = jwtDecode(this.accessToken);
    }

    return this.accessToken;
  };
}
