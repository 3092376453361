import { User as Auth0User } from '@auth0/auth0-react';
import { User as FGUser } from 'oidc-client-ts';
import { LimeadeAuthMethods } from './LimeadeAuthMethods';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthFGProvider>.');
};

export interface LimeadeAuthContextInterface<TUser extends Auth0User | FGUser> {
  error?: Error;
  isAuthenticated: boolean;
  isLoading: boolean;
  limeadeUser?: TUser;

  // ...AuthMethods:
  getAccessToken: () => Promise<string | null>;
  loginWithPopup: () => void;
  logoutWithPopup: () => void;
  loginWithRedirect: () => Promise<void>;
  logoutWithRedirect: () => Promise<void>;
  removeUser: () => Promise<void>;
}

export interface LimeadeAuth0ContextInterface extends LimeadeAuthContextInterface<Auth0User> {}

export interface LimeadeAuthFGContextInterface extends LimeadeAuthContextInterface<FGUser> {
  loginSilent: () => Promise<FGUser | null>;
  loginSilentCallback: () => Promise<void>;
  loginWithRedirectCallback: () => Promise<FGUser>;
}

export const initialLimeadeAuthMethods: LimeadeAuthMethods = {
  getAccessToken: stub,
  loginWithPopup: stub,
  logoutWithPopup: stub,
  loginWithRedirect: stub,
  logoutWithRedirect: stub,
  removeUser: stub,
};
