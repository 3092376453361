const invitationPopUpStyles = {
  infoIcon: {
    fontSize: '18px',
  },
  activityDetails: {
    marginBottom: '15px',
  },
  alertIsCompletedActivity: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.25rem',
  },
  showButton: {
    padding: 0,
  },
  text: {
    fontSize: '14px',
    textTransform: 'capitalize',
  },
};

export default invitationPopUpStyles;
