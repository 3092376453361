import { ProviderState, Providers } from '@microsoft/mgt-element';
import { HttpMethod, TeamsMsal2Config } from '@microsoft/mgt-teams-msal2-provider';
import * as MicrosoftTeams from '@microsoft/teams-js';

import Config from 'src/Config';
import { RoutePath } from 'src/utilities/constants';
import TeamsProvider from './TeamsProvider';

export type GraphClient = typeof Providers.client;

export default class TeamsGraphAuth {
  static graphClientPromise;
  static graphClient: GraphClient;

  static createGraphClientPromise() {
    let resolveGraphClientPromise;

    TeamsGraphAuth.graphClientPromise = new Promise((resolve) => {
      resolveGraphClientPromise = resolve;
    });

    return resolveGraphClientPromise;
  }

  static initialize() {
    let resolveGraphClientPromise = TeamsGraphAuth.createGraphClientPromise();

    const handleProviderUpdated = () => {
      const providerState = Providers.globalProvider.state;

      if (providerState === ProviderState.SignedIn) {
        resolveGraphClientPromise(providerState);
        TeamsGraphAuth.graphClient = Providers.client;
      }
    };

    Providers.onProviderUpdated(handleProviderUpdated);

    TeamsProvider.microsoftTeamsLib = MicrosoftTeams;
    const config: TeamsMsal2Config = {
      clientId: Config.clientId,
      authPopupUrl: RoutePath.Auth,
      httpMethod: HttpMethod.POST,
      autoConsent: true,
      ssoUrl: `${Config.functionEndpoint}/api/auth`,
    };
    Providers.globalProvider = new TeamsProvider(config);
  }

  static async getGraphClient() {
    await TeamsGraphAuth.graphClientPromise;
    return TeamsGraphAuth.graphClient;
  }
}
