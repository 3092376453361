import { app as teamsjsApp, authentication as teamjsAuth } from '@microsoft/teams-js';
import { authenticationStatus } from '../utilities/constants';
import { LoginResult } from 'src/models/LoginResult';

class TeamsJsWrapper {
  static teamsInitialized: boolean;

  static app = {
    async initialize() {
      if (!TeamsJsWrapper.teamsInitialized) {
        await teamsjsApp.initialize();
        TeamsJsWrapper.teamsInitialized = true;
      }
    },

    async ensureTeamsJsInitialized() {
      await TeamsJsWrapper.app.initialize();
    },

    async getContext(): Promise<teamsjsApp.Context> {
      await TeamsJsWrapper.app.ensureTeamsJsInitialized();
      return await teamsjsApp.getContext();
    },

    async openLink(deepLink: string): Promise<void> {
      await TeamsJsWrapper.app.ensureTeamsJsInitialized();
      teamsjsApp.openLink(deepLink);
    },
  };

  static authentication = {
    async authenticate(params?: any): Promise<string> {
      await TeamsJsWrapper.app.ensureTeamsJsInitialized();
      return await teamjsAuth.authenticate(params);
    },

    async notifySuccess(user?: any) {
      await TeamsJsWrapper.app.ensureTeamsJsInitialized();
      const successResult: LoginResult = { status: authenticationStatus.SUCCESS, detail: user };

      return teamjsAuth.notifySuccess(JSON.stringify(successResult));
    },

    async notifyFailure(reason?: string) {
      await TeamsJsWrapper.app.ensureTeamsJsInitialized();
      return teamjsAuth.notifyFailure(reason);
    },
  };
}

export default TeamsJsWrapper;
export const app = TeamsJsWrapper.app;
export const authentication = TeamsJsWrapper.authentication;
