import React from 'react';
import { FlexProps } from '@fluentui/react-northstar';
import ChartBarItem from './ChartBarItem';
import styles from './ChartBar.styles';
import { ChartBarItemProps } from './utils';
import BlockWrapper from '../BlockWrapper';

interface ChartBarProps extends FlexProps {
  items: ChartBarItemProps[];
}

const ChartBar: React.FC<ChartBarProps> = ({ items, ...props }) => {
  if (!items.length) {
    return null;
  }

  const chartBarItems = items.map((item, index) => {
    const lastItem = index === items.length - 1;
    const chartBarItemStyles = !lastItem ? styles.item : {};

    return <ChartBarItem styles={chartBarItemStyles} item={item} key={item.title} />;
  });

  return (
    <BlockWrapper column styles={{ ...styles.root, ...props.styles }}>
      {chartBarItems}
    </BlockWrapper>
  );
};

export default ChartBar;
