import React, { useEffect } from 'react';
import { MgtTemplateProps, PersonCard } from '@microsoft/mgt-react';
import { observer } from 'mobx-react-lite';

import './PersonCardTemplate.css';
import { useTeamsFx } from 'src/lib/useTeamsFx';
import { getMgtClassName } from 'src/utilities/utilities';
import { ColorTheme } from 'src/utilities/constants';
import { ThemeInfoEx } from 'src/ThemeInfoEx';

function injectPersonRootStyles(mgtPersonCard: HTMLElement, theme: ThemeInfoEx): void {
  const personCardRoot = mgtPersonCard.shadowRoot;
  if (!personCardRoot) {
    return;
  }
  const styles = `
  :host .root .expanded-details-container .expanded-details-button svg path {
    stroke: ${theme.colorScheme.personCardExpandPath};
  }`;

  const hasStylesTag = personCardRoot.firstChild?.nodeName?.toLowerCase() === 'style';

  if (!hasStylesTag) {
    const styleTag = document.createElement('style');
    styleTag.innerHTML = styles;
    personCardRoot.insertBefore(styleTag, personCardRoot.firstChild);
  } else {
    const styleTag = personCardRoot.firstChild as HTMLStyleElement;
    styleTag.innerHTML = styles;
  }
}

export const PersonCardTemplate = observer((props: MgtTemplateProps) => {
  const personCardRef = React.useRef<HTMLElement>(null);
  const { person } = props.dataContext;
  const { themeInfo } = useTeamsFx();

  useEffect(() => {
    if (!personCardRef.current) {
      return;
    }

    injectPersonRootStyles(personCardRef.current, themeInfo);
  }, [personCardRef, themeInfo]);

  const personCardClassName = `${getMgtClassName(themeInfo.themeName)}${themeInfo.themeName === ColorTheme.CONTRAST ? ' contrast' : ''}`;

  return <PersonCard ref={personCardRef} personDetails={person} className={personCardClassName} />;
});
