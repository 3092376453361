import React, { useEffect, useState } from 'react';
import { app as teamsjsApp } from '@microsoft/teams-js';

import { app } from 'src/auth/TeamsJsWrapper';
import { useData } from 'src/lib/useData';
import LimeadeLoader from '../Loader/Loader';
import { useStore } from 'src/stores/Root.store';
import { NotificationType, RoutePath } from 'src/utilities/constants';
import logNotification from 'src/utilities/logNotification';
import { useHistory } from 'react-router-dom';
import { ActivityFeed } from 'src/models/ActivityFeed';
import { Invite } from 'src/models/Invite';
import InvitationPopUp from 'src/components/dialogs/InvitationPopUp';
import { isAuthorization } from 'src/utilities/utilities';

export const NotificationReceiver: React.FC = () => {
  const {
    ApplicationStore: { checkNotificationClicked, trackClickedNotification },
    AppAuthStore,
  } = useStore();
  const history = useHistory();
  const { data: context, loading: isContextLoading, error: contextError } = useData(app.getContext);
  const [invitation, setInvitation] = useState<ActivityFeed & Invite>();
  const invitationPopUp = AppAuthStore.isAuthenticated && invitation && <InvitationPopUp invitation={invitation} />;
  const subPageId = (context as teamsjsApp.Context)?.page?.subPageId;

  useEffect(() => {
    if (contextError) {
      throw contextError;
    }
  }, [contextError]);

  useEffect(() => {
    if (subPageId) {
      const subEntity = JSON.parse(subPageId);
      const isClicked = checkNotificationClicked(subEntity.correlationId);
      switch (subEntity.notificationType) {
        case NotificationType.COMPLETE_ACTIVITY:
        case NotificationType.TEAM_POSITION:
        case NotificationType.JOIN_TEAM:
        case NotificationType.CHEERS:
        case NotificationType.SECOND_WELCOME:
          if (!isClicked) {
            logNotification(subEntity);
            trackClickedNotification(subEntity.correlationId);

            if (subEntity.activityId) {
              history.push(RoutePath.ActivityDetailsView.replace(':id', subEntity.activityId));
            }
          }
          break;
        case NotificationType.INVITATION:
          if (!isClicked) {
            setInvitation(subEntity);
          }
          break;
        case NotificationType.JOINED_ACTIVITY_REMINDER_LINK_CLICKED:
          if (subEntity.activityId) {
            history.push(RoutePath.ActivityDetailsView.replace(':id', subEntity.activityId));
          }
          break;
      }
    }
  }, [subPageId, checkNotificationClicked, trackClickedNotification, history]);

  if (isContextLoading) return <LimeadeLoader />;

  return <div>{invitationPopUp}</div>;
};

export const NotificationReceiverWrapper = () => {
  if (isAuthorization()) {
    return null;
  }
  return <NotificationReceiver />;
};
