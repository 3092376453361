import { useContext, useEffect, useState, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useErrorBoundary } from 'react-error-boundary';
import { Dialog, Flex, ToDoListIcon, Text, Header, Button, FlexItem } from '@fluentui/react-northstar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import styles from './InvitationPopUp.styles';
import Teamboard from 'src/components/shared/Teamboard/Teamboard';
import { useData } from 'src/lib/useData';
import { ApiContext } from 'src/api/ApiContext';
import Logger from 'src/logger/Logger';
import { Components, SocialTrackingMode, PopUpEventTypes, RoutePath } from 'src/utilities/constants';
import appStyles from 'src/App.styles';
import { Invite } from 'src/models/Invite';
import { ActivityFeed } from 'src/models/ActivityFeed';
import pendoIdentifierBuilder from 'src/utilities/pendoIdentifierBuilder';
import { useStore } from 'src/stores/Root.store';
import { currentView } from 'src/utilities/utilities';
import { isCompleted } from 'src/utilities/activityUtilities';
import { TeamParticipant } from 'src/models/TeamParticipant';

interface InvitationPopUpProps {
  invitation: ActivityFeed & Invite;
  closeOnOutsideClick?: boolean;
}

const InvitationPopUp: React.FC<InvitationPopUpProps> = ({ invitation, closeOnOutsideClick = false }) => {
  const activityId = invitation.activityId.toString();
  const teamId = invitation.teamId.toString();
  const apiContext = useContext(ApiContext);
  const { ActivityStore, ApplicationStore, UserProfileStore } = useStore();
  const history = useHistory();
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const [showDetails, setDetails] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(true);
  const [isJoining, setIsjoining] = useState(false);

  const { data: activityInfo, error: getActivityError } = useData(
    async () => await ActivityStore.getActivityById(activityId)
  );
  const { data: teamParticipants, error: getTeamParticipantsError } = useData(
    async () => await apiContext.api.getTeamParticipants(activityId, teamId)
  );
  const { data: currentTeamInfo, error: getCurrentTeamInfoError } = useData(
    async () => await apiContext.api.getCurrentTeamInfo(activityId, teamId)
  );

  const title = activityInfo?.title;
  const maxTeamSize = activityInfo?.socialTracking?.maxTeamSize;
  const participantCount = teamParticipants?.length;
  const teamName = currentTeamInfo?.TeamName;
  const limeadeAccountId = UserProfileStore.profile?.limeade_account_id;
  const isJoined = useMemo(
    () => !!teamParticipants?.find((item: TeamParticipant) => item.LimeadeAccountId === limeadeAccountId),
    [teamParticipants, limeadeAccountId]
  );
  const showPopup = isPopupOpen && !isJoined;
  const shouldRedirectToActivity = isJoined;

  const onConfirm = () => {
    Logger.trackPopupOpenOrClose(PopUpEventTypes.INVITATION, false);
    setPopupOpen(false);
  };

  const isTeamFull = (): boolean => {
    return participantCount && participantCount >= maxTeamSize;
  };

  useEffect(() => {
    if (getActivityError || getTeamParticipantsError || getCurrentTeamInfoError) {
      throw getActivityError || getTeamParticipantsError || getCurrentTeamInfoError;
    }
  }, [getActivityError, getTeamParticipantsError, getCurrentTeamInfoError]);

  useEffect(() => {
    if (showPopup) {
      ApplicationStore.trackClickedNotification(invitation.correlationId);
      Logger.trackSendInvitation(activityId, invitation.teamId);
      Logger.trackPopupOpenOrClose(PopUpEventTypes.INVITATION, true);
    }
  }, [showPopup, activityId, invitation.teamId, invitation.correlationId, ApplicationStore]);

  useEffect(() => {
    if (shouldRedirectToActivity) {
      ApplicationStore.trackClickedNotification(invitation.correlationId);
    }
  }, [shouldRedirectToActivity, activityId, teamId, invitation.correlationId, ApplicationStore]);

  const getFullTeamDialog = () => {
    const hasFullTeam = activityInfo && isTeamFull();

    if (!hasFullTeam) {
      return null;
    }

    const onFullTeamConfirm = () => {
      onConfirm();
      history.push(RoutePath.JoinTeam.replace(':id', activityId));
    };

    return (
      <Dialog
        cancelButton={t('buttons.cancel')}
        onCancel={() => onConfirm()}
        onConfirm={onFullTeamConfirm}
        confirmButton={t('invitation.fullTeam.confirmButton')}
        closeOnOutsideClick={closeOnOutsideClick}
        header={t('invitation.headerTitle', { title })}
        content={<Text content={t('invitation.fullTeam.content', { participantCount, maxTeamSize })} />}
        open={showPopup}
        styles={appStyles.dialog}
      />
    );
  };

  const getInvitationDialog = () => {
    const isInvitationDialog = activityInfo && currentTeamInfo && !isTeamFull();

    if (!isInvitationDialog) {
      return null;
    }

    const socialTracking = activityInfo.socialTracking ? SocialTrackingMode.TEAM : SocialTrackingMode.SELF;
    const shortDescription = activityInfo?.description?.shortDescription || '';
    const displayDetails = showDetails && <Text dangerouslySetInnerHTML={{ __html: shortDescription }} />;
    const displayTeamboard = teamParticipants && <Teamboard teamParticipants={teamParticipants} />;
    const buttonText = showDetails ? t('buttons.detailsButton.hide') : t('buttons.detailsButton.show');
    const onClick = () => {
      Logger.trackButtonClick(buttonText, Components.INVITATION_POP_UP);
      try {
        setDetails(!showDetails);
      } catch (error) {
        showBoundary(error);
      }
    };
    const isCompletedActivity = isCompleted(activityInfo);

    const onInvitationConfirm = async () => {
      try {
        setIsjoining(true);
        const isJoinTeamSuccess = await ActivityStore.joinTeam(activityId, teamId, teamName, teamParticipants);
        setIsjoining(false);
        onConfirm();

        if (isJoinTeamSuccess) {
          history.push(RoutePath.ActivityDetailsView.replace(':id', activityId));
        }
      } catch (error) {
        showBoundary(error);
      }
    };

    const alertIsCompletedActivity = isCompletedActivity && (
      <Text content={t('invitation.alert')} styles={styles.alertIsCompletedActivity} as="div" important />
    );

    const invitationDialogContent = (
      <>
        <Text content={t('invitation.default.content', { teamName })} />

        <div style={styles.activityDetails}>
          <Header as="h3" content={t('invitation.default.headerContent', { title })} />

          <Flex column gap="gap.small">
            <Flex gap="gap.smaller">
              <FontAwesomeIcon icon={faCircleInfo} style={styles.infoIcon} />

              <Text content={activityInfo.action} size="medium" truncated styles={styles.text} />
            </Flex>

            <Flex gap="gap.smaller">
              <ToDoListIcon size="large" />

              <Text content={activityInfo?.trackingStrategy?.frequency} size="medium" />
            </Flex>
          </Flex>
        </div>

        {displayDetails}

        <Button text primary content={buttonText} onClick={onClick} style={styles.showButton} />

        {displayTeamboard}

        {alertIsCompletedActivity}
      </>
    );

    const dialogButtons = (
      <Flex>
        <FlexItem push>
          <Flex gap="gap.small">
            <Button content={t('buttons.cancel')} onClick={onConfirm} />

            <Button
              data-insightId={pendoIdentifierBuilder({
                component: 'JoinButton',
                view: currentView(),
                socialTracking,
                trackingType: activityInfo.trackingType,
                frequency: activityInfo.frequency,
              })}
              content={t('buttons.join')}
              onClick={onInvitationConfirm}
              primary
              loading={isJoining}
              disabled={isJoining || isCompletedActivity}
            />
          </Flex>
        </FlexItem>
      </Flex>
    );

    return (
      <Dialog
        closeOnOutsideClick={closeOnOutsideClick}
        header={t('invitation.headerTitle', { title })}
        content={invitationDialogContent}
        open={showPopup}
        styles={appStyles.dialog}
        footer={dialogButtons}
      />
    );
  };

  if (shouldRedirectToActivity) {
    return <Redirect to={`/activity/${activityId}`} />;
  }

  if (!showPopup) {
    return null;
  }

  return (
    <>
      {getFullTeamDialog()}

      {getInvitationDialog()}
    </>
  );
};
export default InvitationPopUp;
