import { DateTime } from 'luxon';
import Logger, { Events } from '../logger/Logger';

const logNotification = (subEntity: any): void => {
  const utcClickDateTime = DateTime.now().toUTC().toSeconds();
  const secondsSinceBeingSent = Math.abs(Number(utcClickDateTime) - Number(subEntity.sendingTime));
  const timeSinceBeingSent = secondsToHms(secondsSinceBeingSent);
  const notificationParams = {
    notificationType: subEntity.notificationType,
    timeSinceBeingSent,
    correlationId: subEntity.correlationId,
  };
  Logger.trackEvent(Events.NotificationClicking, notificationParams);
};

const secondsToHms = (seconds: number): string => {
  seconds = Number(seconds);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
  return hDisplay + mDisplay + sDisplay;
};

export default logNotification;
