import { useEffect, useRef } from 'react';
import { FontIcon } from '@fluentui/react';
import { Flex } from '@fluentui/react-northstar';
import { Person as PersonMgtReact, PersonCardInteraction, ViewType } from '@microsoft/mgt-react';

import { useTeamsFx } from 'src/lib/useTeamsFx';
import { getMgtClassName } from 'src/utilities/utilities';
import { PersonCardTemplate } from '../PersonCardTemplate';
import personStyles from './Person.styles';

interface PersonProps {
  aadUserId?: string;
  displayName?: string;
  ariaHidden?: boolean;
}

const Person = (props: PersonProps) => {
  const { aadUserId, displayName, ariaHidden } = props;
  const { themeInfo } = useTeamsFx();

  const doneMuteUserAvatar = useRef(false);
  const personMgtRef = useRef<HTMLElement>(null);
  const personMgtShadowDOM = personMgtRef.current?.shadowRoot;

  useEffect(() => {
    if (personMgtShadowDOM && !doneMuteUserAvatar.current) {
      const mutationObserver = new MutationObserver((records, observer) => {
        const personRoot = personMgtShadowDOM.querySelector('.root .flyout .person-root');

        if (personRoot) {
          personRoot.setAttribute('tabindex', '-1');
          doneMuteUserAvatar.current = true;
          observer.disconnect();
        }
      });

      mutationObserver.observe(personMgtShadowDOM, {
        childList: true,
        subtree: true,
      });

      return () => {
        mutationObserver.disconnect();
      };
    }
  }, [personMgtShadowDOM]);

  if (aadUserId) {
    return (
      <Flex gap="gap.smaller" vAlign="center" hAlign="stretch" aria-hidden={ariaHidden}>
        <PersonMgtReact
          ref={personMgtRef}
          userId={aadUserId}
          view={ViewType.twolines}
          line1Property="displayName"
          line2Property="jobTitle"
          personCardInteraction={PersonCardInteraction.hover}
          className={getMgtClassName(themeInfo.themeName)}
        >
          <PersonCardTemplate template="person-card" />
        </PersonMgtReact>
      </Flex>
    );
  }

  return (
    <Flex gap="gap.smaller" vAlign="center" hAlign="stretch" aria-hidden={ariaHidden}>
      <div style={personStyles.personAvatar}>
        <FontIcon iconName="Contact" style={personStyles.contactIcon} />
      </div>
      <p style={personStyles.personName}>
        {displayName}
        <span style={personStyles.asterisk}>*</span>
      </p>
    </Flex>
  );
};

export default Person;
