import React from "react";
import { useState, createContext, useCallback } from "react";
import VisuallyHidden from "../VisuallyHidden";

export interface LoadingAnnouncementProps {
    children?: React.ReactNode;
};

export type AnnouncementContentType = {
    message?: string | null;
    hidden: boolean;
};

export type LoadingAnnouncementContextType = {
    setAnnouncementContent: (message: string | null) => void;
    setAnnouncementToggle: (value: boolean) => void;
};

const AnnouncementBlock: React.FC<AnnouncementContentType> = ({ message, hidden }) => (
    <VisuallyHidden aria-hidden={hidden}>
        <div role="status" aria-live="polite" aria-relevant="additions text" aria-atomic="true">{message}</div>
    </VisuallyHidden>
);

export const LoadingAnnouncementContext = createContext<LoadingAnnouncementContextType | null>(null);

const LoadingAnnouncement: React.FC<LoadingAnnouncementProps> = ({ children }): JSX.Element | null => {
    const [announcementMessage, setAnnouncementMessage] = useState<string | null>(null);
    const [announcementStatus, setAnnouncementStatus] = useState<boolean>(true);

    const putAnnouncementMessage = useCallback((msg: string | null) => {
        setAnnouncementMessage(msg);
    }, []);

    const switchAnnouncementStatus = useCallback((value: boolean) => {
        setAnnouncementStatus(value);
    }, []);

    return (
        <LoadingAnnouncementContext.Provider value={{ setAnnouncementContent: putAnnouncementMessage, setAnnouncementToggle: switchAnnouncementStatus }}>
            <AnnouncementBlock message={announcementMessage} hidden={!announcementStatus} />
            {children}
        </LoadingAnnouncementContext.Provider>
    );
};

export default LoadingAnnouncement;