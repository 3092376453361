const oopsViewStyles = {
  wrapper: { height: '100%' },
  oopsWrapper: {
    width: '60%',
    minWidth: '350px',
  },
  oopsImage: {
    width: '60%',
    minWidth: '350px',
  },
  oopsMessage: {
    whiteSpace: 'pre-line',
  },
  oopsBtnContainer: {
    marginTop: '30px',
    width: '80%',
    minWidth: '350px',
    justifyContent: 'space-around',
  },
};

export default oopsViewStyles;
