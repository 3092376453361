import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LimeadeLoader from '../components/shared/Loader/Loader';
import { LOGIN_CALLBACK_IN_PROGRESS } from '../utilities/constants';
import { app } from './TeamsJsWrapper';

function Auth0Login() {
  const auth0 = useAuth0();

  useEffect(() => {
    const login = () => {
      app.getContext().then((context) => {
        auth0.loginWithRedirect({ login_hint: context.user?.loginHint });
      });
    };

    window.localStorage.removeItem(LOGIN_CALLBACK_IN_PROGRESS);
    login();
  }, [auth0]);

  return <LimeadeLoader />;
}

export default Auth0Login;
