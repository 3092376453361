import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@fluentui/react-northstar';
import { UserFriendsIcon } from '@fluentui/react-icons-northstar';
import Board from '../Board/Board';
import LimeadeLoader from '../Loader/Loader';
import { useData } from 'src/lib/useData';
import { useEffect } from 'react';
import { TeamParticipant, TeamParticipantWrapper } from 'src/models/TeamParticipant';
import Person from '../Person/Person';
import { useStore } from 'src/stores/Root.store';
import EmphasizedText from '../EmphasizedText';

interface TeamboardProps {
  teamParticipants: TeamParticipant[];
}

const Teamboard = (props: TeamboardProps) => {
  const { t } = useTranslation();
  const { UserMappingStore, LocalizationStore } = useStore();
  const { teamParticipants } = props;

  const createHeader = () => {
    return {
      key: 'teamboard header row',
      items: [
        {
          content: (
            <Flex gap="gap.smaller" vAlign="center">
              <UserFriendsIcon />
              <Text content={t('customStrings.teamBoard')} />
            </Flex>
          ),
        },
      ],
    };
  };

  const createBody = (rows: TeamParticipantWrapper[]) => {
    return rows?.map((value) => {
      return {
        key: value.LimeadeAccountId,
        styles: { height: '70px' },
        items: [
          {
            content: <Person aadUserId={value.mapping?.oid} displayName={value.DisplayName} />,
            truncateContent: true,
          },
        ],
      };
    });
  };

  const { reload, loading, data, error } = useData(
    async () => {
      const limeadedIds = teamParticipants.map((teamParticipant) => teamParticipant.LimeadeAccountId);
      await UserMappingStore.fetchUserMappingsByIds(limeadedIds);
      const teammateWithMappings: TeamParticipantWrapper[] = [];
      for (const teamParticipant of teamParticipants) {
        const mapping = UserMappingStore.getUserMappingById(teamParticipant.LimeadeAccountId);
        const combine = { ...teamParticipant, mapping };
        teammateWithMappings.push(combine);
      }
      return teammateWithMappings;
    },
    { auto: false }
  );

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  useEffect(() => {
    if (teamParticipants.length > 0) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamParticipants.length]);

  if (loading) {
    return <LimeadeLoader />;
  }

  if (teamParticipants.length === 0) {
    return (
      <Flex column>
        <Text content={t('customStrings.emptyTeam')} />
      </Flex>
    );
  }

  const getNotAddedToTeamsText = () => {
    const found = data?.find((item) => !item.mapping?.oid);
    if (found) {
      return (
        <EmphasizedText as="p" styles={{ marginTop: 5, marginBottom: 5 }}>
          {LocalizationStore.getString('Fow_NotUsedAppOnTeams')}
        </EmphasizedText>
      );
    }
    return <></>;
  };

  return <Board header={createHeader()} rows={createBody(data)} warningNotAddedToTeams={getNotAddedToTeamsText()} />;
};

export default Teamboard;
